<template>
  <div class="MassCancel">
    <validation-observer
      ref="appointmentRef"
      novalidate
    >
      <b-container
        id="masscancel-appointments-container"
        fluid
      >
        <b-row>
          <content-header :title="$t('appointmentsMassiveCancellation')" />
        </b-row>
        <div class="main-content">
          <div class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-6 col-lg-3"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectcompany')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('company')"
                :options="availableCompanies"
                :placeholder="$t('select')"
                :is-multiple="false"
                :value="model.companies"
                @change="onCompanyChanged($event)"
                :disabled="isVerifying"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-6 col-lg-3"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectlocalization')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('localization')"
                :options="availableLocations"
                :placeholder="$t('select')"
                :value="model.locations"
                :is-multiple="false"
                @change="onLocationChanged($event)"
                :disabled="isVerifying"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-6 col-lg-3 pl-0 pr-0"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectservices')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('services')"
                :options="availableServices"
                class="col-sm-12"
                :placeholder="$t('select')"
                :select-all-enabled="true"
                :value="model.services"
                @change="onServicesChanged($event)"
                :disabled="isVerifying"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-6 col-lg-3 pl-0 pr-0"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectspecialist')"
                fieldtext="name"
                fieldvalue="id"
                class="col-sm-12"
                :is-multiple="true"
                :label="$t('specialists')"
                :options="availableSpecialists"
                :placeholder="$t('select')"
                :select-all-enabled="true"
                :value="model.specialists"
                @change="onSpecialistsChanged($event)"
                :disabled="isVerifying"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-6 col-md-6 col-lg-3 pl-0 pr-0"
              rules="required"
            >
              <base-datetime-picker
                v-if="!isVerifying"
                :error="errors[0]"
                :error-msg="$t('mustselectdate')"
                v-model="model.startDate"
                class="col-sm-12"
                format="dd/MMM/yyyy"
                :title="$t('fromDate')"
                type="date"
                @change="onStartDateChanged($event)"
              />
              <b-form-group v-if="isVerifying" class="pl-3" :label="$t('startDate')">
                <label class="strong mt-1">{{ model.startDate | date }}</label>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-6 col-md-6 col-lg-3 pl-0 pr-0"
              rules="required"
            >
              <base-datetime-picker
                v-if="!isVerifying"
                :error="errors[0]"
                :error-msg="$t('mustselectdate')"
                v-model="model.endDate"
                class="col-sm-12"
                format="dd/MMM/yyyy"
                :title="$t('toDate')"
                type="date"
                @change="onEndDateChanged($event)"
              />
              <b-form-group v-if="isVerifying" class="pl-3" :label="$t('toDate')">
                <label class="strong mt-1">{{ model.endDate | date }}</label>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-6 col-md-6 col-lg-3 pl-0 pr-0"
              rules="required"
            >
              <base-time-picker
                v-if="!isVerifying"
                :error="errors[0]"
                :error-msg="$t('mustselecttime')"
                class="col-sm-12"
                icon="clock"
                :minute-step="1"
                :title="$t('hourFrom')"
                :value="model.startTime"
                @input="OnStartTimeChanged($event)"
              />
              <b-form-group v-if="isVerifying" class="pl-3" :label="$t('hourFrom')">
                <label class="strong mt-1">{{ model.startTime | hour }}</label>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-6 col-md-6 col-lg-3 pl-0 pr-0"
              rules="required"
            >
              <base-time-picker
                v-if="!isVerifying"
                :error="errors[0]"
                :error-msg="$t('mustselecttime')"
                class="col-sm-12"
                icon="clock"
                :minute-step="1"
                :title="$t('hourTo')"
                :value="model.endTime"
                @input="OnEndTimeChanged($event)"
              />
              <b-form-group v-if="isVerifying" class="pl-3" :label="$t('hourTo')">
                <label class="strong mt-1">{{ model.endTime | hour }}</label>
              </b-form-group>
            </validation-provider>
            <div  v-if="!isVerifying || (isVerifying && model.ignoredDates.length)" class="col-sm-12 col-md-6 col-lg-3">
              <b-form-group :label="$t('ignoredDates') + ':'" class="pt-0 ignored-dates">
                <base-datetime-picker
                  v-if="!isVerifying "
                  class="theme-orange"
                  type="date"
                  @change="onIgnoreDateSelected($event)"
                  :disabled="isVerifying"
                  :value="ignoredDate"
                />
                <br v-if="!isVerifying" />
                <b-row v-if="model.ignoredDates">
                  <b-col
                    cols="12"
                    sm="12"
                    class="pt-2"
                  >
                    <label
                      v-for="ignored in model.ignoredDates"
                      :key="ignored"
                      class="label-round"
                      :value="ignored"
                      style="background-color: #d8d8d8;font-size: 14px;width: 25%;float: left; min-width: 120px;"
                    >
                      <span
                        class="float-left"
                        style="position: absolute; top: 25%"
                      />
                      {{ ignored | date }}
                      <span class="float-right mr-2" v-if="!isVerifying">
                        <i
                          class="
                            fal
                            fa-times-circle
                            text-dark
                            color-black
                            fs-18
                          "
                          @click="removeIgnoredDateHandler(ignored)"
                        />
                      </span>
                    </label>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
            <div v-if="!isVerifying || (isVerifying && model.ignoredHours.length)" class="col-sm-12 col-md-6 col-lg-3 pl-0 pr-0">
              <b-form-group class="ignored-hours" :label="$t('ignoredHours') + ':'">
                <base-time-picker
                  class="col-sm-12"
                  icon="clock"
                  :minute-step="1"
                  @input="onIgnoreHourSelected($event)"
                  v-if="!isVerifying"
                  :value="ignoredHour"
                />
                <br v-if="!isVerifying" />
                <b-row v-if="model.ignoredHours">
                  <b-col
                    cols="12"
                    sm="12"
                    class="ignored-hour-container"
                  >
                    <label
                      v-for="ignored in model.ignoredHours"
                      :key="ignored"
                      class="label-round"
                      style="background-color: #d8d8d8;font-size: 14px;width: 20%;float: left; min-width: 120px;"
                      :value="ignored"
                    >
                      <span
                        class="float-left"
                        style="position: absolute; top: 25%"
                      />
                      {{ ignored }}
                      <span class="float-right mr-2" v-if="!isVerifying">
                        <i
                          class="
                            fal
                            fa-times-circle
                            text-dark
                            color-black
                            fs-18
                          "
                          @click="removeIgnoredHourHandler(ignored)"
                        />
                      </span>
                    </label>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-6 col-md-6 col-lg-3 pl-3 pr-3"
              rules="required"
            >
              <base-input
                v-if="!isVerifying"
                v-model="model.comment"
                :error="errors[0]"
                :error-msg="$t('commentIsRequired')"
                :label="$t('cancellationReason')"
                :textarea="true"
                :max-length="8000"
              />
              <b-form-group v-if="isVerifying" class="pl-0" :label="$t('cancellationReason')">
                <label class="strong mt-1">{{ model.comment }}</label>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
        <div class="row" :class="{ 'mt-3': isCompleted }">
          <div v-if="isVerifying" :class="{ 'col-md-12': !isCompleted, 'col-md-6': isCompleted }">
            <div id="masscancel-appointment-verification" class="col-md-12 float-left" :class="{ 'search-result-orange': isCompleted, 'mr-2': isCompleted }">
              <div class="row pt-1">
                <content-header :title="$t('verifyAppointmentSpacesToDelete')" />
              </div>
              <div v-if="!isCompleted" class="main-content">
                <div class="col-sm-12">
                  <p>
                    <label>{{ $t('appointmentSpacesToDelete') }}: </label>
                    <span class="ml-2">{{ spacesToCancelTotal }}</span>
                  </p>
                </div>
              </div>
              <div v-else>
                <p>
                    <label>{{ $t('appointmentSpacesToDelete') }}: </label>
                    <span class="ml-2">{{ spacesToCancelTotal }}</span>
                  </p>
              </div>
            </div>
          </div>
          <div v-if="isCompleted" class="col-md-6">
            <div id="masscancel-appointment-completed" class="col-md-12 search-result-green float-right ml-2">
              <div class="row pt-1">
                <content-header :title="$t('deletedAppointmentSpaces')" />
              </div>
              <div v-if="!isCompleted" class="main-content">
                <div class="col-sm-12">
                  <p>
                    <label>{{ $t('deletedAppointmentSpaces') }}: </label>
                    <span class="ml-2">{{ deletedSpaces }}</span>
                  </p>
                </div>
              </div>
              <div v-else>
                <p>
                    <label>{{ $t('deletedAppointmentSpaces') }}: </label>
                    <span class="ml-2">{{ deletedSpaces }}</span>
                  </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 pt-3">
            <base-filled-button
              v-if="!isVerifying"
              icon-class="fal fa-eye"
              :on-click="onVerify"
              :text="$t('review')"
              class="float-right"
            />
            <base-filled-button
              v-if="canProcess"
              icon-class="fal fa-check"
              :on-click="onProcess"
              :text="$t('process')"
              class="float-right"
            />
            <base-filled-button
              v-if="!isVerifying || isCompleted"
              bg-color="#707070"
              icon-class="fal fa-times-circle"
              :text="$t('reset')"
              :on-click="onReset"
              class="float-left"              
            />
            <base-filled-button
              v-if="isVerifying && !isCompleted"
              bg-color="#707070"
              icon-class="fal fa-times-circle"
              :text="$t('cancel')"
              :on-click="onCancel"
              class="float-left"
            />
          </div>
        </div>
      </b-container>
    </validation-observer>
  </div>
</template>

<script>
import FilterMultiSelect from '@/components/FilterMultiSelect';
import BaseTimePicker from '@/components/BaseTimePicker.vue';
import BaseInput from '@/components/BaseInput.vue';
import contentHeader from '@/components/Header';

import { getCompaniesWithCoordinatedAppointments as _getCompaniesWithCoordinatedAppointments } from '@/services/CompanyService';
import { getLocationsWithCoordinatedAppointments as _getLocationsWithCoordinatedAppointments } from '@/services/LocationService';
import { getServiceTypesByLocation as _getServiceTypesByLocation } from '@/services/ServicesService';
import { cancelCoordinatedAppointments as _CancelCoordinatedAppointments, 
         verifyCoordinatedAppointmentsToCancel as _verifyCoordinatedAppointmentsToCancel
       } from '@/services/AppointmentService';

import SpecialistService from '@/services/SpecialistService.js';

import _formatter from 'moment';

export default {
  title: 'Appointments Mass Cancellation',
  name: 'MassCancellation',
  components: {
    FilterMultiSelect,
    BaseTimePicker,
    contentHeader,
    BaseInput
  },
  data() {
    return this.initializeState();
  },
  computed: {
    canProcess() {
      return this.spacesToCancelTotal > 0 && this.isVerifying && !this.isCompleted;
    }
  },
  async mounted() {
    await this.loadCompanies();
  },
  methods: {
    clearLocations() {
      this.availableLocations = [];
      this.model.locations = [];
    },
    clearServices() {
      this.availableServices = [];
      this.model.services = [];
    },
    clearSpecialists() {
      this.availableSpecialists = [];
      this.model.specialists = [];
    },
    clearIgnoredHours() {
      this.model.ignoredHours = [];
    },
    async loadCompanies() {
      await _getCompaniesWithCoordinatedAppointments()
        .then((response) => {
          this.availableCompanies = response.data;
          if (this.availableCompanies.length == 1) {
            this.getAvailableLocalizations(
              this.model.companies[0].value,
            );
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onCompanyChanged(value) {
      this.model.companies = [value];

      this.clearLocations();
      this.clearServices();
      this.clearSpecialists();

      if(value) {
        await this.getAvailableLocations(value.id);
      }
    },
    async onLocationChanged(value) {
      this.clearServices();
        this.clearSpecialists();

      if(value) {
        this.model.locations = [value];

        await this.getAvailableServices(value.id);
        this.getAvailableSpecialists();
      }
    },
    async onServicesChanged(value) {
      this.model.services = value;
    },
    async onSpecialistsChanged(value) {
      this.model.specialists = value;
    },
    onStartDateChanged(date) {
      if(!date)
      {
        this.model.startDate = null; return;
      }

      this.model.startDate = _formatter(date).format();
    },
    onEndDateChanged(date) {
      if(!date)
      {
        this.model.endDate = null; return;
      }

      this.model.endDate = _formatter(date).format();
    },
    OnStartTimeChanged(time) {
      if(!time)
      {
        this.model.startTime = null; return;
      }

      this.model.startTime = _formatter(time).format();
    },
    OnEndTimeChanged(time) {
      if(!time)
      {
        this.model.endTime = null; return;
      }

      this.model.endTime = _formatter(time).format();
    },
    setDateTime(date, time) {
      let _date = this.$moment(date);
      let _time = this.$moment(time);
      return _date.hours(_time.hours()).minutes(_time.minutes()).format();
    },
    onIgnoreDateSelected(date) {
      this.ignoredDate = date;

      if (this.$moment(date).isValid()) {
        const formatedValue = this.$moment(date).format('YYYYMMDD');

        var isAdded = this.model.ignoredDates.find(
          (element) => element == this.$moment(formatedValue).format(),
        );

        if (!isAdded) {
          this.model.ignoredDates.push(
            this.$moment(formatedValue).format(),
          );
        }
      }
    },
    removeIgnoredDateHandler(date) {
      const foundIndex = this.model.ignoredDates.findIndex(
        (a) => a.date == date,
      );

      this.model.ignoredDates.splice(foundIndex, 1);
    },

    onIgnoreHourSelected(hour) {
      this.ignoredHour = hour;

      if (this.$moment(hour).isValid()) {
        const formatedValue = this.$moment(hour).format('hh:mm A');

        var isAdded = this.model.ignoredHours.find(
          (element) => element == formatedValue,
        );

        if (!isAdded) {
          this.model.ignoredHours.push(
            formatedValue,
          );
        }
      }
    },
    removeIgnoredHourHandler(hour) {
      const foundIndex = this.model.ignoredHours.findIndex(
        (a) => a.hour == hour,
      );

      this.model.ignoredHours.splice(foundIndex, 1);
    },

    async getAvailableLocations(companyId) {
      await _getLocationsWithCoordinatedAppointments({
        companyId: companyId
      })
        .then((response) => {
          this.availableLocations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableServices(locationConfigurationId) {
      await _getServiceTypesByLocation({ 
        locationConfigurationId: locationConfigurationId 
      })
        .then((response) => {
          this.availableServices = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    getAvailableSpecialists() {
      SpecialistService.getDropDownListForCreateSpace(
        this.model.companies[0].id,
        0,
        0,
        0,
        this.model.locations[0].id,
      )
        .then((response) => {
          this.availableSpecialists = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onVerify() {
      const isValid = await this.$refs.appointmentRef.validate();

      if (!isValid) {
        return;
      }

      const payload = {
        locationConfigurationId: this.model.locations[0].id,
        specialistIds: this.model.specialists.map((x) => x.id),
        serviceTypeIds: this.model.services.map((x) => x.id),
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        startTime: _formatter(this.model.startTime).format('HH:mm'),
        endTime: _formatter(this.model.endTime).format('HH:mm'),
        datesToExclude: this.model.ignoredDates,
        timesToExclude: this.model.ignoredHours.map(x => _formatter(x, ['h:mm A']).format('HH:mm')),
        comment: this.model.comment
      }
      
      await _verifyCoordinatedAppointmentsToCancel(payload).then((response) => {
          this.spacesToCancelTotal = response.data;

          this.isVerifying = true;

          this.spacesToDeletePayload = payload;

          this.ignoredDate = null;
          this.ignoredHour = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onProcess() {
      await _CancelCoordinatedAppointments(this.spacesToDeletePayload).then((response) => {
          this.deletedSpaces = response.data;
          this.isCompleted = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onReset() {
      Object.assign(this.$data, this.initializeState());

      this.loadCompanies();
    },
    onCancel() {
      this.isVerifying = false;
    },
    initializeState() {
      return {
        availableCompanies: [],
        availableLocations: [],
        availableServices: [],
        availableSpecialists: [],
        model: {
          companies: [],
          locations: [],
          services: null,
          specialists: null,
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
          ignoredDates: [],
          ignoredHours: [],
          comment: null
        },
        spacesToDeletePayload: null,
        spacesToCancelTotal: 0,
        deletedSpaces: 0,
        isVerifying: false,
        isCompleted: false,

        ignoredDate: null,
        ignoredHour: null
      };
    }
  },
};
</script>

<style lang="scss" scoped>
  .main-content {
    background: $color-white 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 23px 23px 10px 23px !important;
  }
  .main-content-title {
    text-align: left;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: $color-primary;
    opacity: 1;
  }

  ::v-deep .c-timePicker > div {
    width: 100%;
  }

  ::v-deep .ignored-dates .bv-no-focus-ring.col-form-label {
    padding-bottom: 0!important;
  }

  ::v-deep .ignored-dates .c-filterMultiSelect, ::v-deep .ignored-hours .c-timePicker {
    margin-bottom: 0;
  }

  .ignored-hour-container {
    padding-left: 30px;
  }  

  ::v-deep .ignored-hours .col-form-label {
    padding-left: 15px;
  }

  #masscancel-appointment-verification, #masscancel-appointment-completed {
    margin-top: 15px;
  }
  
  #masscancel-appointment-verification p, #masscancel-appointment-completed p {
    font-weight: bold;
  }

  #masscancel-appointment-verification span, #masscancel-appointment-completed span {
    color: $color-primary;
  }

  .search-result-green {
    border: 2px solid #0c8a29 !important;
    background-color: #f5ffeb;
    border-radius: 10px;
  }

  .search-result-orange {
    border: 2px solid #FF8105 !important;
    background-color: #FFF2E5;
    border-radius: 10px;
  }
</style>
