<template>
  <div class="CancelAppointment">
    <b-container id="cancel-appointment-container" fluid>
      <content-header :title="$t('cancel')" />
      <separator class="my-3" :text="$t('appointmentinformation')" />
      <div v-if="data" class="cancel">
        <b-row>
          <b-col
            class="d-flex flex-column align-items-center justify-content-center"
            lg="2"
          >
            <div
              class="cancel-baloon"
              :style="{
                backgroundColor: getStatusColor(data.appointmentStatusId),
              }"
            >
              {{ data.appointmentStatusName }}
            </div>
            <span class="cancel-date">{{
              data.appointmentTimeStart | date
            }}</span>
            <span class="cancel-appointmentId">{{
              data.clientAppointmentId
            }}</span>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-around pl-5 border-left"
            cols="6"
            lg="2"
          >
            <p>
              <span>{{ $t('service') }}:</span><br /><span>{{
                data.serviceTypeName
              }}</span>
            </p>
            <p>
              <span>{{ $t('date') }}:</span><br /><span>{{
                data.appointmentTimeStart | date
              }}</span>
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-around pl-5"
            cols="6"
            lg="2"
          >
            <p>
              <span>{{ $t('localization') }}:</span><br /><span>{{
                data.locationName
              }}</span>
            </p>
            <p>
              <span>{{ $t('schedule') }}:</span><br /><span>{{
                getSchedule
              }}</span>
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-around pl-5"
            cols="6"
            lg="2"
          >
            <p>
              <span>{{ $t('coordinator') }}:</span><br /><span>{{
                data.specialistNameFull
              }}</span>
            </p>
            <p>
              <span>{{ $t('attendees') }}:</span><br /><span>{{
                data.attendees
              }}</span>
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-around pl-5"
            cols="6"
            lg="2"
          >
            <p>
              <span>{{ $t('phone') }}:</span><br />
              <base-input-display
                v-model="data.clientTelephone"
                mask-type="Phone"
              />
            </p>
            <p>
              <span>{{ $t('address') }}:</span><br /><span>{{
                data.locationAddressLine1
              }}</span>
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-around pl-5"
            cols="6"
            lg="2"
          >
            <p>
              <span>{{ $t('coordinator') }}:</span><br /><span>Admin</span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex flex-column align-items-center justify-content-center"
            cols="6"
            lg="2"
          />
          <b-col
            class="d-flex flex-column align-items-around pl-5 border-left"
            cols="10"
          >
            <p>
              <span>{{ $t('comment') }}:</span><br /><span>{{
                data.statusComment
              }}</span>
            </p>
          </b-col>
        </b-row>
        <validation-observer ref="cancelAppointment" novalidate>
          <b-row
            class="m-2 p-3 rounded"
            style="background-color: $color-app-background"
          >
            <validation-provider
              v-slot="{ errors }"
              class="col-6"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectreason')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('cancelreasons')"
                :options="cancelReasons"
                :placeholder="$t('select')"
                :value="selectedReason"
                @change="OnReasonChange($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-12"
              rules="required"
            >
              <base-input
                v-model="confirmedComment"
                :error="errors[0]"
                :error-msg="$t('mustwritecomment')"
                fieldtext="name"
                fieldvalue="id"
                :textarea="true"
              />
            </validation-provider>
          </b-row>
        </validation-observer>
      </div>
      <b-row class="d-flex pb-5">
        <b-col class="d-flex justify-content-between">
          <base-filled-button
            bg-color="#707070"
            icon-class="far fa-chevron-circle-left"
            :on-click="OnCancel"
            :text="$t('previous')"
          />
          <base-filled-button
            icon-class="fal fa-arrow-circle-right"
            :on-click="OnContinue"
            :text="$t('continue')"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import contentHeader from '@/components/Header';
import Separator from '@/components/Separator';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import BaseInput from '@/components/BaseInput.vue';
import { getAppointmentById as _getAppointmentById } from '@/services/AppointmentService';
import { cancelAppointment as _cancelAppointment } from '@/services/AppointmentService';
import { getCancellationReasons as _getCancellationReasons } from '@/services/CodeService';
import BaseInputDisplay from '@/components/BaseInputDisplay';
import moment from 'moment';

export default {
  data() {
    return {
      data: null,
      cancelReasons: ['1', '2', '3'],
      selectedReason: [],
      confirmedComment: '',
      appointmentId: null,
    };
  },
  components: {
    contentHeader,
    Separator,
    FilterMultiSelect,
    BaseInput,
    BaseInputDisplay,
  },
  computed: {
    getStatusColor() {
      return (status) => {
        switch (status) {
          case 1:
            return '#F8EA80';
          case 4:
            return '#A4E5B2';
          case 3:
          case 14:
            return '#D5B89A';
          case 13:
            return '#FCC9C8';
          default: {
            return '#D5B89A';
          }
        }
      };
    },
    getStatusName() {
      return (status) => {
        switch (status) {
          case 1:
            return this.$t('coordinated');
          case 3:
            return this.$t('cancelled');
          case 4:
            return this.$t('completed');
          case 5:
            return this.$t('approved');
          case 6:
            return this.$t('denied');
          case 8:
            return this.$t('extended');
          case 9:
            return this.$t('confirmed');
          case 13:
            return this.$t('registered');
          case 14:
            return this.$t('incomplete');
          default: {
            return 'Desconocido';
          }
        }
      };
    },
    getSchedule() {
      return `${moment(this.data?.appointmentTimeStart).format(
        'hh:mm A',
      )} - ${moment(this.data?.appointmentTimeEnd).format('hh:mm A')}`;
    },
  },
  methods: {
    async loadAppointment() {
      await _getAppointmentById(this.appointmentId)
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async cancelAppointment() {
      await _cancelAppointment(
        parseInt(this.appointmentId),
        this.selectedReason[0].id,
        this.confirmedComment,
      )
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({
              name: 'RegisteredAppoinments',
              params: {
                origin: 'canceled',
              },
            });
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async cancellationReasons(statusCode) {
      await _getCancellationReasons(statusCode)
        .then((response) => {
          this.cancelReasons = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    OnReasonChange(item) {
      this.selectedReason = [item];
    },
    async OnContinue() {
      const isValid = await this.$refs.cancelAppointment.validate();

      if (!isValid) {
        return;
      }
      this.cancelAppointment();
    },
    OnCancel() {
      this.$router.push({
        name: 'RegisteredAppoinments',
        params: {
          origin: 'cancel',
        },
      });
    },
  },
  mounted() {
    this.appointmentId = this.$route.params.appointmentId;
    this.loadAppointment();
    this.cancellationReasons(3);
  },
};
</script>
<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
#cancel-appointment-container {
  background-color: $color-app-background;
  min-height: 100vh;
}
.cancel {
  background-color: $color-white;
  border-radius: 5px;
  opacity: 1;
  padding: 18px !important;
  margin: 15px 0;
  &-baloon {
    width: 168px;
    height: 37px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-display: lato;
    font-weight: bold;
  }
  &-date {
    color: $color-font-secondary;
    font-weight: normal;
    margin: 10px 0;
  }
  &-appointmentId {
    font-weight: bold;
    margin: 5px 0;
  }
  p {
    margin: 10px 0;
    span:first-child {
      color: $color-font-secondary;
      font-size: 14px;
    }
    span:last-child {
      color: $color-font-success;
      font-size: 16px;
    }
  }
}
</style>
