<template>
  <div>
    <content-header :title="$t('registeredAppointments')" />
    <div class="container-fluid">
      <b-container class="main-page-container" fluid>
        <b-row>
          <sub-header :title="$t('filter')" />
        </b-row>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('search')"
          @search="getAppointments(1)"
        />
      </b-container>

      <alert-message
        v-if="!isDateValid"
        icon-class="far fa-times-circle"
        :message="
          $t(
            this.selectedFilters.datefrom > this.selectedFilters.dateto
              ? 'cantDoSearchGreaterThanDays'
              : 'cantDoSearchHigherThanDays',
          )
        "
      />

      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <separator
        v-if="registeredAppoinments.data.length"
        class="my-3"
        :text="$t('registeredAppointments')"
      />
      <search-result
        v-for="result in registeredAppoinments.data"
        :key="result.clientAppointmentId"
        :value="result"
        @cancel="OpenCancelConfirmation(result.clientAppointmentId)"
        @complete="OnComplete(result.clientAppointmentId)"
        @edit="onEdit(result.clientAppointmentId)"
        @send-appointment-confirmation="
          openSendAppointmentConfirmationModal(result.clientAppointmentId)
        "
        @show-documents="
          openAppointmentDocumentsModal(result.clientAppointmentId)
        "
        @show-stamps="openAppointmentStampsModal(result.clientAppointmentId)"
        @reschedule-appointment="
          onRescheduleAppointment(
            result.clientAppointmentId,
            result.locationConfigurationId,
          )
        "
      />
      <custom-pagination
        v-if="registeredAppoinments.count > 0"
        id="pgPager"
        :data="registeredAppoinments"
        :page-size="pageSize"
        @pagination-go-page="getAppointments($event)"
        @pagination-rows-per-page="getAppointments(pageIndex, $event)"
      />

      <custom-modal
        id="appointment-stamps-modal"
        ref="appointment-stamps-ref"
        size="md"
      >
        <appointment-stamps
          :appointment-id="selectedAppointmentId"
          :stamps-info="appointmentStampsInfo"
          @close="$refs['appointment-stamps-ref'].hide()"
        />
      </custom-modal>

      <custom-modal
        id="appointment-documents-modal"
        ref="appointment-documents-ref"
        size="md"
      >
        <appointment-documents
          :appointment-id="selectedAppointmentId"
          @close="$refs['appointment-documents-ref'].hide()"
        />
      </custom-modal>
      <custom-modal
        id="appointment-edit-modal"
        ref="appointment-edit-ref"
        size="lg"
      >
        <edit-appointment
          :client-appointment-id="selectedAppointmentId"
          @cancel="
            () => {
              $refs['appointment-edit-ref'].hide();
            }
          "
          @save="
            () => {
              $refs['appointment-edit-ref'].hide();
              getAppointments();
            }
          "
        />
      </custom-modal>
      <base-alert
        v-model="showCancelModal"
        :message="$t('cancelAppointmentConfimation')"
        :title="$t('cancelAppointment') | uppercase"
        @on-confirm="OnCancel($event)"
      />
      <base-alert
        v-model="showSendAppointmentConfirmationModal"
        :message="$t('sendAppointmentConfirmation')"
        :title="$t('resend') | uppercase"
        @on-confirm="onSendAppointmentConfirmation($event)"
      />
    </div>
    <custom-modal id="customAlert" ref="customAlert" size="md">
      <AlertMessage
        icon-class="fas fa-exclamation-circle"
        :message="$t('previousAppointmentFound')"
      />
      <b-row class="d-flex justify-content-center">
        <b-col class="d-flex justify-content-center" cols="4">
          <base-filled-button
            bg-color="#707070"
            :on-click="
              () => {
                this.$refs.customAlert.hide();
              }
            "
            :text="$t('continue')"
          />
        </b-col>
      </b-row>
    </custom-modal>
  </div>
</template>
<script>
import sessionState from '@/mixins/sessionState';
import digitalCollectionMixin from '@/mixins/appointment/digitalCollectionMixin';

import moment from 'moment';
import contentHeader from '@/components/Header';
import FilterManager from '@/components/FilterManager';
import Separator from '@/components/Separator';
import SearchResult from '@/views/appointments/components/SearchResult';
import AlertMessage from '@/components/basics/alerts/AlertMessage.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import AppointmentStamps from './components/AppointmentStamps.vue';
import AppointmentDocuments from '@/components/AppointmentDocuments.vue';
import EditAppointment from './components/EditAppointment.vue';
import BaseAlert from '@/components/BaseAlert-old.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import SpecialistService from '@/services/SpecialistService.js';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SubHeader from '@/components/SubHeader.vue';
import {
  getLocationsForSpaceCreation as _getLocationsForSpaceCreation,
  getCoordinators as _getCoordinators,
} from '@/services/LocationService';
import { getServiceTypesForSpaceCreation as _getServiceTypesForSpaceCreation } from '@/services/ServicesService';
import { getAppointments as _getAppointments,
         sendConfirmation as _sendConfirmation
       } from '@/services/AppointmentService';
import {  } from '@/services/AppointmentService';
import { getInitialFiltersData as _getInitialFiltersData } from '@/services/AppointmentService';
import { getAppointmentStatuses as _getAppointmentStatuses } from '@/services/CodeService';

export default {
  name: 'RegisteredAppoinments',
  components: {
    contentHeader,
    FilterManager,
    Separator,
    SearchResult,
    CustomModal,
    AppointmentStamps,
    BaseAlert,
    CustomPagination,
    AppointmentDocuments,
    EditAppointment,
    AlertMessage,
    CustomNotFound,
    SubHeader,
  },
  data() {
    return {
      loading: false,
      filters: {
        status: [],
        phone: [],
        appointmentId: [],
        location: [],
        company: [],
      },
      coordinators: [],
      service: [],
      specialist: [],
      selectedFilters: {
        status: [],
        service: [],
        phone: '',
        appointmentId: '',
        specialist: [],
        location: [],
        company: [],
        coordinator: [],
        name: '',
        email: '',
        datefrom: this.$moment().format(),
        dateto: this.$moment().format(),
      },
      selectedService: null,
      noResultsFound: false,
      showCancelModal: false,
      showSendAppointmentConfirmationModal: false,
      selectedAppointmentId: null,
      appointmentStampsInfo: null,
      paginationConfig: {
        currentPage: 1,
        totalItemCount: 0,
        pageCount: 10,
        count: 0,
      },
      registeredAppoinments: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
    };
  },
  mixins: [sessionState, digitalCollectionMixin],
  computed: {
    filtersConfig() {
      return [
        {
          rules: 'required',
          name: 'datefrom',
          label: this.$t('dateFrom'),
          component: 'BaseDatetimePicker',
          format: 'dd/MMM/yyyy',
          placeholder: this.$t('date'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.datefrom,
          onChanged: this.onDateFromChanged,
        },
        {
          rules: 'required',
          name: 'dateto',
          label: this.$t('dateTo'),
          component: 'BaseDatetimePicker',
          format: 'dd/MMM/yyyy',
          placeholder: this.$t('date'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.dateto,
          minDateTime: this.datefrom,
          onChanged: this.onDateToChanged,
        },
        {
          rules: 'required',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.company,
          required: true,
          placeholder: this.$t('select'),
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: false,
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          options: this.filters.location,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
        {
          rules: '',
          name: 'status',
          label: this.$t('status'),
          component: 'FilterMultiSelect',
          options: this.filters.status,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.status,
          onChanged: this.onStatusChanged,
        },
        {
          rules: '',
          name: 'service',
          label: this.$t('service'),
          component: 'FilterMultiSelect',
          options: this.service,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.service,
          onChanged: this.onServiceChanged,
        },
        {
          rules: '',
          name: 'specialist',
          label: this.$t('specialist'),
          component: 'FilterMultiSelect',
          options: this.specialist,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.specialist,
          onChanged: this.onSpecialistChanged,
        },
        {
          rules: 'numeric',
          name: 'appointmentId',
          label: this.$t('appointmentNumber'),
          component: 'BaseInput',
          placeholder: this.$t('appointment'),
          maskType: 'AppointmentId',
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.appointmentId,
          value: this.selectedFilters.appointmentId,
        },
        {
          rules: 'email',
          name: 'email',
          label: this.$t('email'),
          component: 'BaseInput',
          placeholder: this.$t('email'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustentercorrectemail'),
          initialValue: this.selectedFilters.email,
          value: this.selectedFilters.email,
        },
        {
          rules: 'numeric',
          name: 'phone',
          label: this.$t('phone'),
          component: 'BaseInput',
          placeholder: this.$t('phone'),
          maskType: 'Phone',
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.phone,
          value: this.selectedFilters.phone,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
        {
          rules: '',
          name: 'coordinator',
          label: this.$t('coordinator'),
          component: 'FilterMultiSelect',
          options: this.coordinators,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.coordinator,
          onChanged: this.onCoordinatorChange,
        },
      ];
    },
    datefrom: {
      get: function () {
        return this.selectedFilters.datefrom;
      },
      set: function (newDate) {
        const _datefrom = moment(newDate).format();
        this.selectedFilters.datefrom = _datefrom;
        if (!this.isDateValid) {
          this.dateto = _datefrom;
        }
      },
    },
    dateto: {
      get: function () {
        return this.selectedFilters.dateto;
      },
      set: function (newDate) {
        this.selectedFilters.dateto = moment(newDate).format();
      },
    },
    isDateValid() {
      let timeStart = moment(this.datefrom);
      let timeEnd = moment(this.dateto);
      let dayDiff = timeEnd.diff(timeStart, 'months', true);
      return dayDiff >= 0 && dayDiff <= 1;
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getInitialFiltersData()
        .then((response) => {
          const collections = response.data.collections;
          this.filters.company = collections.companies || [];
          if (this.filters.company.length == 1) {
            this.selectedFilters.company = [this.filters.company[0]];
            this.getAvailableLocalizations(this.selectedFilters.company[0].id);
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await _getAppointmentStatuses()
        .then((response) => {
          this.filters.status = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableLocalizations(companyId) {
      this.selectedFilters.location = [];
      await _getLocationsForSpaceCreation(companyId)
        .then((response) => {
          this.filters.location = response.data;
          if (this.filters.location.length == 1) {
            this.selectedFilters.location = [this.filters.location[0]];
            this.getAvailableServices(this.selectedFilters.location[0].id);
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableServices(localizationId) {
      await _getServiceTypesForSpaceCreation(localizationId)
        .then((response) => {
          this.service = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    getAvailableSpecialists() {
      this.selectedFilters.specialist = [];
      SpecialistService.getDropDownListForCreateSpace(
        this.selectedFilters.company[0].id,
        0,
        0,
        this.selectedFilters.location[0].id,
      ).then((response) => {
        this.specialist = response.data;
      });
    },
    getClient(appointmentId) {
      if (appointmentId && this.registeredAppoinments.data.length > 0) {
        const {
          clientAppointmentId,
          clientId,
          clientNameFull,
          clientTelephone,
        } = this.registeredAppoinments.data.find(
          (c) => c.clientAppointmentId === appointmentId,
        );

        return {
          clientAppointmentId: String(clientAppointmentId),
          clientId,
          clientNameFull,
          email: '',
          clientTelephone,
          providerId: 0,
        };
      }
      return null;
    },
    onStatusChanged(value) {
      if (!value) return (this.selectedFilters.status = []);
      this.selectedFilters.status = [value];
    },
    onServiceChanged(value) {
      if (!value) {
        this.selectedFilters.service = [];
        return;
      }
      this.selectedFilters.service = [value];
      this.getAvailableSpecialists();
    },
    onSpecialistChanged(value) {
      if (!value) {
        this.selectedFilters.specialist = [];
        return;
      }
      this.selectedFilters.specialist = [value];
    },
    onLocationChanged(value) {
      this.selectedFilters.service = [];
      this.selectedFilters.specialist = [];
      this.selectedFilters.coordinator = [];
      this.coordinators = [];
      this.specialist = [];
      this.service = [];

      if (!value) {
        this.selectedFilters.location = [];

        return;
      }

      this.selectedFilters.location = [value];
      const locationId = this.selectedFilters.location[0].id;
      const locationConfigurationId =
        this.selectedFilters.location[0].locationConfigurationId;
      this.getAvailableServices(locationId);
      this.getCoordinators(locationConfigurationId);
    },
    onCompanyChanged(value) {
      this.selectedFilters.company = [value];
      const companyId = this.selectedFilters.company[0].id;
      this.getAvailableLocalizations(companyId);
    },
    onDateFromChanged(value) {
      this.datefrom = value;
    },
    onDateToChanged(value) {
      this.dateto = value;
    },
    openAppointmentStampsModal(appointmentId) {
      this.selectedAppointmentId = appointmentId;
      this.appointmentStampsInfo = null;
      this.$refs['appointment-stamps-ref'].show();
    },
    openAppointmentDocumentsModal(appointmentId) {
      this.selectedAppointmentId = appointmentId;
      this.$refs['appointment-documents-ref'].show();
    },
    async onRescheduleAppointment(appointmentId) {
      const stampsValidationResult = await this.validateAndGetStamps(appointmentId);

      if (stampsValidationResult.stampsData.isValid) {
        this.$router.push({
          name: 'CreateAppointment',
          params: {
            rescheduleMode: true,
            clientAppointmentId: appointmentId,
          },
        });
      } else {
        this.selectedAppointmentId = appointmentId;
        this.appointmentStampsInfo = stampsValidationResult.stampsData;
        this.$refs['appointment-stamps-ref'].show();
      }
    },
    OnCancel(event) {
      if (!event) return;
      const appointmentId = this.selectedAppointmentId;
      this.$router.push({
        name: 'CancelAppointment',
        params: {
          appointmentId,
        },
      });
    },
    OpenCancelConfirmation(appointmentId) {
      this.selectedAppointmentId = appointmentId;
      this.showCancelModal = true;
    },
    OnComplete(appointmentId) {
      this.$router.push({
        name: 'CompleteAppointment',
        params: {
          appointmentId,
        },
      });
    },
    onEdit(appointmentId) {
      this.selectedAppointmentId = appointmentId;
      this.$refs['appointment-edit-ref'].show();
    },
    openSendAppointmentConfirmationModal(appointmentId) {
      this.selectedAppointmentId = appointmentId;
      this.showSendAppointmentConfirmationModal = true;
    },
    onSendAppointmentConfirmation(event) {
      if (!event) return;
      _sendConfirmation(this.selectedAppointmentId).catch((error) =>
        this.ShowToast('Error', error.message, 'error'),
      );
    },
    async getAppointments(pageIndex = null, pageSize = null) {
      if (!this.isDateValid) {
        return;
      }
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        TimeStart: moment(this.selectedFilters.datefrom).format('YYYY-MM-DD'),
        TimeEnd: moment(this.selectedFilters.dateto).format('YYYY-MM-DD'),
        CompanyId: this.selectedFilters.company[0].id,
        LocationId:
          this.selectedFilters.location.length > 0
            ? this.selectedFilters.location[0].id
            : null,
        StatusCode:
          this.selectedFilters.status.length > 0
            ? this.selectedFilters.status[0].id
            : null,
        ServiceTypeCd:
          this.selectedFilters.service.length > 0
            ? this.selectedFilters.service[0].id
            : null,
        SpecialistId:
          this.selectedFilters.specialist.length > 0
            ? this.selectedFilters.specialist[0].id
            : null,
        AppointmentId: this.selectedFilters.appointmentId || null,
        CoordinatorId:
          this.selectedFilters.coordinator?.length > 0
            ? this.selectedFilters.coordinator[0].id
            : null,
        Email: this.selectedFilters.email || null,
        Phone: this.selectedFilters.phone || null,
        Name: this.selectedFilters.name || null,
        CreatedDirectly: false,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getAppointments(payload)
        .then(({ data }) => {
          this.registeredAppoinments = data;
          this.registeredAppoinments.data.length
            ? (this.noResultsFound = false)
            : (this.noResultsFound = true);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      // Persist current state of this view
      this.saveSessionData(
        'registeredAppointmentsData',
        Object.assign({}, this.$data),
      );
    },
    getCoordinators(locationConfigurationId) {
      _getCoordinators(locationConfigurationId).then((response) => {
        this.coordinators = response.data;
      });
    },
    onCoordinatorChange(value) {
      if (!value) return (this.selectedFilters.coordinator = []);

      this.selectedFilters.coordinator = [value];
    },
    resetFilters() {
      Object.assign(this.$data, {
        filters: {
          status: [],
          service: [],
          phone: [],
          appointmentId: [],
          specialist: [],
          location: [],
          company: [],
          coordinator: [],
        },
        selectedFilters: {
          status: [],
          service: [],
          phone: '',
          appointmentId: '',
          specialist: [],
          location: [],
          company: [],
          coordinators: [],
          name: '',
          email: '',
          datefrom: this.$moment().format(),
          dateto: this.$moment().format(),
        },
        selectedService: null,
        noResultsFound: false,
        showCancelModal: false,
        showSendAppointmentConfirmationModal: false,
        selectedAppointmentId: null,
        paginationConfig: {
          currentPage: 1,
          totalItemCount: 0,
          pageCount: 10,
          count: 0,
        },
        registeredAppoinments: {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        },
        pageSize: 10,
      });
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadSessionData(
      'registeredAppointmentsData',
    );
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }

    if (
      this.$route.params.origin == 'complete' ||
      this.$route.params.origin == 'cancel'
    ) {
      this.getAppointments();
    } else if (
      this.$route.params.origin == 'completed' ||
      this.$route.params.origin == 'canceled'
    ) {
      this.selectedFilters.appointmentId = '';
      this.selectedFilters.email = '';
      this.selectedFilters.phone = '';
      this.selectedFilters.name = '';
      this.getAppointments();
    } else {
      this.removeSessionData('registeredAppointmentsData');
      this.resetFilters();
      this.loadInitialFilters();
    }
  },
};
</script>
<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
#confirmCancel {
  h6 {
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
  }
  h5 {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
