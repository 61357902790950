<template>
  <div>
    <div :class="`result ${getStatusColor(value.appointmentStatusId)}`">
      <b-row>
        <b-col
          class="d-flex flex-column align-items-center justify-content-center"
          lg="2"
        >
          <div
            :id="`statusname-${value.clientAppointmentId}`"
            class="result-baloon"
          >
            {{ getStatusName(value.appointmentStatusId) }}
          </div>
          <b-popover
            :target="`statusname-${value.clientAppointmentId}`"
            :title="$t('statusInformation')"
            triggers="hover"
          >
            <p>
              <span>{{ $t('statusDate') }}:&nbsp;</span
              ><span class="text-break">{{
                value.appointmentStatusCreateDate | datetime
              }}</span
              ><br />
              <span>{{ $t('statusCreatedBy') }}:&nbsp;</span
              ><span class="text-break">{{
                value.appointmentStatusModifyBy
              }}</span
              ><br />
              <span>{{ $t('statusCreatedForReason') }}:&nbsp;</span
              ><span class="text-break">{{
                value.appointmentStatusReasonName || $t('withoutReason')
              }}</span>
            </p>
          </b-popover>
          <span class="result-date">{{
            value.appointmentTimeStart | date
          }}</span>
          <span class="result-appointmentId">{{
            value.clientAppointmentId
          }}</span>
        </b-col>
        <b-col
          class="d-flex flex-column align-items-around justify-content-around pl-4 border-left"
          cols="6"
          lg="2"
        >
          <p>
            <span>{{ $t('date') }}:</span><br /><span class="text-break">{{
              value.appointmentTimeStart | date
            }}</span>
          </p>
          <p>
            <span>{{ $t('company') }}:</span><br /><span class="text-break">{{
              value.companyName
            }}</span>
          </p>
          <p v-if="value.coordinatorName">
            <span>{{ $t('coordinator') }}:</span><br /><span
              class="text-break"
              >{{ value.coordinatorName }}</span
            >
          </p>
        </b-col>
        <b-col
          class="d-flex flex-column align-items-around justify-content-center pl-4"
          cols="6"
          lg="2"
          xs="6"
        >
          <p>
            <span>{{ $t('schedule') }}:</span><br /><span class="text-break">{{
              getSchedule
            }}</span>
          </p>
          <p>
            <span>{{ $t('localization') }}:</span><br /><span
              class="text-break"
              >{{ value.locationName }}</span
            >
          </p>
        </b-col>
        <b-col
          class="d-flex flex-column align-items-around justify-content-around pl-4"
          cols="6"
          lg="2"
        >
          <p>
            <span>{{ $t('service') }}:</span><br /><span class="text-break">{{
              value.serviceTypeName
            }}</span>
          </p>
          <p>
            <span>{{ $t('client') }}:</span><br /><span class="text-break">{{
              value.clientNameFull
            }}</span>
          </p>
        </b-col>
        <b-col
          class="d-flex flex-column align-items-around justify-content-around pl-4"
          cols="6"
          lg="2"
        >
          <p>
            <span>{{ $t('specialist') }}:</span><br /><span
              class="text-break"
              >{{ value.specialistNameFull }}</span
            >
          </p>
          <p>
            <span>{{ $t('phone') }}:</span><br />
            <base-input-display
              v-model="value.clientTelephone"
              mask-type="Phone"
            />
          </p>
        </b-col>
        <b-col lg="2">
          <b-row>
            <b-col class="col-12 col-sm-12 col-md-12 col-lg-2">
              <action-button :actions="actions" :text="$t('actions')" />
            </b-col>
            <b-col
              v-if="value.haveStamp"
              class="text-center"
              :lg="value.haveDocument ? 6 : 12"
            >
              <img
                class="cursor-pointer"
                :src="require('@/assets/colecturia-logo.png')"
                @click="$emit('show-stamps')"
              />
            </b-col>
            <b-col v-if="value.haveDocument" :lg="value.haveStamp ? 6 : 12">
              <b-button
                block
                class="text-capitalize"
                variant="primary"
                @click="$emit('show-documents')"
              >
                <i class="far fa-file-alt" /> {{ $t('see') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import ClientAppointmentStatus from '@/constants/status/ClientAppointmentStatus';
import BaseInputDisplay from '@/components/BaseInputDisplay';
import moment from 'moment';

export default {
  components: {
    ActionButton,
    BaseInputDisplay,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayComplete: {
      type: Boolean,
      default: () => true,
    },
    displayConfirm: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('complete'),
          iconClass: 'fal fa-flag',
          iconColor: '#198E0F',
          action: () => this.$emit('complete'),
          visible: () =>
            this.value.appointmentStatusId ==
              ClientAppointmentStatus.Coordinated && this.displayComplete,
        },
        {
          label: this.$t('resendconfirmation'),
          iconClass: 'fas fa-envelope',
          iconColor: '#198E0F',
          action: () => this.$emit('send-appointment-confirmation'),
          visible: () =>
            this.value.appointmentStatusId ==
              ClientAppointmentStatus.Coordinated && this.displayConfirm,
        },
        {
          label: this.$t('rescheduleAppointment'),
          iconClass: 'fal fa-calendar-check',
          iconColor: '#FFA500',
          action: () => this.$emit('reschedule-appointment'),
          visible: () =>
            this.value.appointmentStatusId ==
              ClientAppointmentStatus.Coordinated ||
            this.value.appointmentStatusId ==
              ClientAppointmentStatus.Cancelled ||
            this.value.appointmentStatusId ==
              ClientAppointmentStatus.NotPresent,
        },
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#03c2fc',
          action: () => this.$emit('edit'),
          visible: () =>
            this.value.appointmentStatusId ==
            ClientAppointmentStatus.Coordinated,
        },
        {
          label: this.$t('cancel'),
          iconClass: 'fal fa-times-circle',
          iconColor: '#FF0000',
          action: () => this.$emit('cancel'),
          visible: () =>
            this.value.appointmentStatusId ==
            ClientAppointmentStatus.Coordinated,
        },
      ],
    };
  },
  computed: {
    getStatusColor() {
      return (status) => {
        switch (status) {
          case 1:
            return 'coordinated';
          case 4:
            return 'completed';
          case 3:
            return 'cancelled';
          case 14:
            return 'incomplete';
          case 13:
            return 'registered';
          default: {
            return 'default';
          }
        }
      };
    },
    getStatusName() {
      return (status) => {
        switch (status) {
          case 1:
            return this.$t('coordinated');
          case 3:
            return this.$t('cancelled');
          case 4:
            return this.$t('completed');
          case 5:
            return this.$t('approved');
          case 6:
            return this.$t('denied');
          case 8:
            return this.$t('extended');
          case 9:
            return this.$t('confirmed');
          case 13:
            return this.$t('registered');
          case 14:
            return this.$t('incomplete');
          case 16:
            return this.$t('Recoordinated');
          case 17:
            return this.$t('NotPresent');
          default: {
            return this.$t('Unknown');
          }
        }
      };
    },
    getSchedule() {
      return `${moment(this.value.appointmentTimeStart).format(
        'hh:mm A',
      )} - ${moment(this.value.appointmentTimeEnd).format('hh:mm A')}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.result {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 18px !important;
  margin: 15px 0;
  @include respond(phone) {
    margin: 25px 0;
  }
  &-divider {
    height: 95%;
    width: 1px;
    background-color: $color-font-secondary;
    opacity: 0.22;
  }
  &-baloon {
    width: 168px;
    height: 37px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-display: lato;
    font-weight: bold;
    cursor: help;
  }
  &-date {
    color: $color-font-secondary;
    font-weight: normal;
    margin: 10px 0;
  }
  &-appointmentId {
    font-weight: bold;
    margin: 5px 0;
  }
  .text-black {
    color: black !important;
  }
  img {
    width: 75px;
    height: 35px;
  }
  p {
    margin: 10px 0;
    span:first-child {
      color: $color-font-secondary;
      font-size: 14px;
    }
    span:last-child {
      color: $color-font-primary;
      font-size: 16px;
    }
  }
}
.coordinated {
  border-left: solid 5px $color-coordinated;
  .result-baloon {
    background-color: $color-coordinated;
  }
}
.completed {
  border-left: solid 5px $color-completed;
  .result-baloon {
    background-color: $color-completed;
  }
}
.cancelled {
  border-left: solid 5px $color-cancelled;
  .result-baloon {
    background-color: $color-cancelled;
  }
}
.incomplete {
  border-left: solid 5px $color-incomplete;
  .result-baloon {
    background-color: $color-incomplete;
  }
}
.registered {
  border-left: solid 5px $color-registered;
  .result-baloon {
    background-color: $color-registered;
  }
}
.default {
  border-left: solid 5px $color-registered;
  .result-baloon {
    background-color: $color-registered;
  }
}
</style>
