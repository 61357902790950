<template>
  <div class="CreateAppointment">
    <validation-observer ref="formRef" novalidate>
      <b-container id="appointments-container" fluid>
        <b-row>
          <content-header
            :title="
              rescheduleMode
                ? $t('rescheduleAppointment')
                : $t('requestappointment')
            "
          />
        </b-row>
        <div class="main-content">
          <b-row>
            <validation-provider
              v-slot="{ errors }"
              class="col-md-6"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectservices')"
                fieldtext="text"
                fieldvalue="value"
                :options="availableServices"
                :placeholder="$t('service')"
                :value="appointmentSearchModel.service"
                :disabled="rescheduleMode"
                @change="OnServiceChange($event)"
              />
            </validation-provider>
            <validation-provider
              v-if="isSubServiceFieldVisible"
              class="col-sm-12 col-md-6"
            >
              <filter-multi-select
                fieldtext="name"
                fieldvalue="value"
                :options="availableSubServices"
                :placeholder="$t('subService')"
                :value="appointmentSearchModel.subService"
                :disabled="rescheduleMode"
                @change="OnSubServiceChange($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-md-6"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectorganization')"
                fieldtext="text"
                fieldvalue="value"
                :label="null"
                :options="availableOrganizations"
                :placeholder="$t('organization')"
                :value="appointmentSearchModel.organization"
                :disabled="rescheduleMode"
                @change="OnOrganizationChange($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :class="`col-md-4 ${
                isSubServiceFieldVisible ? 'col-md-6' : null
              }`"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectcompany')"
                fieldtext="text"
                fieldvalue="value"
                :label="isSubServiceFieldVisible ? undefined : $t('company')"
                :options="availableCompanies"
                :placeholder="$t('select')"
                :value="appointmentSearchModel.company"
                :disabled="rescheduleMode"
                @change="OnCompanyChange($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :class="`col-md-4 ${
                isSubServiceFieldVisible ? 'col-md-6' : null
              }`"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectlocalization')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('localization')"
                :options="availableLocalizations"
                :placeholder="$t('select')"
                :value="appointmentSearchModel.localization"
                :disabled="rescheduleMode"
                @change="OnLocalizationChange($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :class="`col-md-4 ${
                isSubServiceFieldVisible ? 'col-md-6' : null
              }`"
            >
              <filter-multi-select
                :clearable="true"
                :error="errors[0]"
                :error-msg="$t('mustselectspecialist')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('specialist')"
                :options="availableSpecialists"
                :placeholder="`-- ${$t('all')} --`"
                :value="appointmentSearchModel.specialist"
                @change="OnSpecialistChange($event)"
              />
            </validation-provider>
            <div class="col-md-12" />
            <base-datetime-picker
              before
              class="col-sm-6 col-lg-3"
              format="dd/MMM/yyyy"
              :min-datetime="
                coordinateInternalAppointmentsToday ? today : tomorrow
              "
              set-initial-value
              :title="$t('dateFrom')"
              type="date"
              :value="appointmentSearchModel.startDate"
              @change="OnStartDateChanged($event)"
            />
            <base-datetime-picker
              before
              class="col-sm-6 col-lg-3"
              format="dd/MMM/yyyy"
              :max-datetime="maxDateTime"
              :min-datetime="appointmentSearchModel.startDate"
              set-initial-value
              :title="$t('dateTo')"
              type="date"
              :value="appointmentSearchModel.endDate"
              @change="OnEndtDateChanged($event)"
            />
            <BaseTimePicker
              class="col-sm-6 col-lg-3"
              icon="clock"
              :max-datetime="officeCloseTime"
              :minute-step="1"
              :title="$t('hourFrom')"
              :value="startHour"
              @input="OnStartTimeChanged($event)"
            />
            <BaseTimePicker
              class="col-sm-6 col-lg-3"
              icon="clock"
              :max-datetime="officeCloseTime"
              :minute-step="1"
              :title="$t('hourTo')"
              :value="endHour"
              @input="OnEndTimeChanged($event)"
            />
            <b-col lg="12">
              <div
                v-if="
                  !coordinateInternalAppointments &&
                  appointmentSearchModel.localization.length
                "
                class="alert alert-warning"
                role="alert"
              >
                {{ $t('youCannotCoordinateAppointmentsForThisLocation') }}
              </div>
            </b-col>
            <b-col class="d-inline-flex justify-content-end mt-lg-2" lg="12">
              <SearchInput
                v-if="companyConfig?.filterServicesByExtraField1"
                id="specialField1"
                :placeholder="$t('loanNumber')"
                :text="$t('search')"
                mask="##########"
                @click="handleSearch"
                @clear="handleClear"
              />
              <div>
                <base-filled-button
                  class="mr-0"
                  icon-class="far fa-search"
                  :on-click="OnSubmit"
                  :text="$t('search')"
                  :disabled="
                    !coordinateInternalAppointments ||
                    !appointmentSearchModel.localization.length
                  "
                />
              </div>
            </b-col>
          </b-row>
          <div
            v-show="showResults && !showConfirmationModal"
            class="Results mt-3"
          >
            <b-row>
              <b-col>
                <div
                  class="caption border-bottom mb-4 mt-5 text-left main-content-title pb-2"
                >
                  {{ $t('searchresults') }}
                </div>
              </b-col>
            </b-row>
            <b-row class="pt-4">
              <full-calendar
                id="appointmentCalendar"
                ref="appointmentCalendar"
                :first-date="this.calendarRange.beginDate"
                :item-click="handleSelectedAppointment"
                :items="appointments"
                :last-date="this.calendarRange.endDate"
                :month-items="appointmentsMonth"
                :show-service="showService"
                :type="calendarType"
                @on-start-end-date-changed="onStartEndDateChanged"
              />
            </b-row>
          </div>
          <div v-if="showConfirmationModal" class="AppointmentConfirmation">
            <b-row>
              <b-col>
                <div
                  class="caption border-bottom text-left mt-5 main-content-title pb-2"
                >
                  {{ $t('appointmentadditionalinformation') }}
                </div>
              </b-col>
            </b-row>
            <b-row class="pt-4">
              <b-col cols="12">
                <div
                  class="AppointmentConfirmation-Success d-flex flex-column flex-sm-row justify-content-center align-items-center"
                >
                  <i class="fal fa-check-circle" />
                  <p class="text-center">
                    {{ $t('appointmentinformationreceived') }}.<br />{{
                      $t('appointmentinformationreceived2')
                    }}:
                    <span style="font-weight: 900 !important">{{
                      confirmedAppointment.clientAppointmentId
                    }}</span>
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row
              centered
              class="d-flex justify-content-start justify-content-lg-center align-items-center my-5 ml-lg-5 AppointmentConfirmation-Info"
            >
              <b-col class="d-flex flex-column" cols="12" lg="3" sm="6" xl="2">
                <p class="my-lg-4">
                  <span>{{ $t('client') }}: </span><br /><span>{{
                    confirmedAppointment.clientName
                  }}</span>
                </p>
                <p class="my-lg-4">
                  <span>{{ $t('schedule') }}: </span><br /><span
                    >{{ date }} {{ getSchedule }}</span
                  >
                </p>
              </b-col>
              <b-col class="d-flex flex-column" cols="12" lg="3" sm="6" xl="2">
                <p class="my-lg-4">
                  <span>{{ $t('office') }}: </span><br /><span>{{
                    confirmedAppointment.locationName
                  }}</span>
                </p>
                <p class="my-lg-4">
                  <span>{{ $t('address') }}: </span><br /><span>{{
                    getAddress
                  }}</span>
                </p>
              </b-col>
              <b-col class="d-flex flex-column" cols="12" lg="3" sm="6" xl="2">
                <p class="my-lg-4">
                  <span>{{ $t('service') }}: </span><br /><span>{{
                    confirmedAppointment.serviceTypeName
                  }}</span>
                </p>
                <p class="my-lg-4">
                  <span>{{ $t('attendees') }}: </span><br /><span>{{
                    confirmedAppointment.attendees
                  }}</span>
                </p>
              </b-col>
            </b-row>
            <b-row
              class="pb-5"
              v-if="allowCoordinateOtherService && !rescheduleMode"
            >
              <b-col class="d-flex justify-content-center my-4" cols="12">
                <h5>{{ $t('confirmCoordinateAnotherService') }}</h5>
              </b-col>
              <b-col class="d-flex justify-content-center" cols="12">
                <base-filled-button
                  bg-color="#707070"
                  class="btn-cancel"
                  icon-class="far fa-times-circle"
                  :on-click="OnNoCoordinateOtherService"
                  :text="$t('no')"
                />
                <base-filled-button
                  icon-class="fad fa-save"
                  :on-click="OnCoordinateOtherService"
                  :text="$t('yes')"
                />
              </b-col>
            </b-row>
          </div>
        </div>
        <alert-message
          v-if="!showConfirmationModal && !showResults"
          class="mt-3"
          :message="$t('pleaseSelectOptionsSearch')"
        />
      </b-container>
    </validation-observer>
    <validation-observer ref="ModalRef" novalidate>
      <custom-modal
        id="confirmAppointmentModal"
        ref="confirmAppointmentModal"
        :title="$t('confirmAppointmentAndVerify')"
      >
        <b-row>
          <b-col>
            <div
              class="caption border-bottom mb-4 text-left main-content-title pb-2 confirmAppointmentModal-Header"
            >
              {{ $t('appointmentInformationBellow') }}:
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-xs-12 col-md-4">
            <p>
              <span>{{ $t('service') }}: </span
              ><span>{{ appointmentConfirmationModel.serviceName }}</span>
            </p>
            <p>
              <span>{{ $t('subService') }}: </span
              ><span>{{ appointmentConfirmationModel.subServiceName }}</span>
            </p>
            <p>
              <span>{{ $t('date') }}: </span
              ><span>{{
                $moment(appointmentConfirmationModel.date).format(
                  'dddd D/MMMM/YYYY',
                )
              }}</span>
            </p>
            <p>
              <span>{{ $t('schedule') }}: </span
              ><span
                >{{
                  $moment(appointmentConfirmationModel.startHour).format(
                    'hh:mm A',
                  )
                }}
                -
                {{
                  $moment(appointmentConfirmationModel.endHour).format(
                    'hh:mm A',
                  )
                }}</span
              >
            </p>
          </b-col>
          <b-col class="col-xs-12 col-md-8">
            <p>
              <span>{{ appointmentConfirmationModel.addressType }}: </span
              ><span
                >{{ appointmentConfirmationModel.addressLine1 }},
                {{ appointmentConfirmationModel.municipality }}, PR
                {{ appointmentConfirmationModel.zipCode }}</span
              >
            </p>
            <p>
              <span>{{ $t('specialist') }}: </span>
              <span class="specialist-name"
                >{{ appointmentConfirmationModel.specialistNameFull }}
              </span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div
              class="caption border-bottom mb-4 text-left main-content-title pb-2 confirmAppointmentModal-Header"
            >
              {{ $t('confirmContactInformation') }}:
            </div>
          </b-col>
        </b-row>
        <b-row>
          <validation-provider
            v-slot="{ errors }"
            class="col-sm-12 col-md-4 d-flex align-items-center"
            :rules="{
              email: true,
              required: serviceConfiguration.emailRequired,
            }"
          >
            <base-input
              v-model="newAppointmentModel.email"
              class="w-100"
              :error="errors[0]"
              :error-msg="$t('mustEnterEmail')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('email')"
              :disabled="rescheduleMode"
              @on-enter="searchProfile"
            />
            <i
              v-if="!rescheduleMode"
              class="searchIcon fal fa-search"
              @click="searchProfile"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            rules="required"
          >
            <base-input
              v-model="newAppointmentModel.firstName"
              :error="errors[0]"
              :error-msg="$t('mustEnterName')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('name')"
              :disabled="rescheduleMode"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            rules="required"
          >
            <base-input
              v-model="newAppointmentModel.paternalLastName"
              :error="errors[0]"
              :error-msg="$t('paternalLastNameRequired')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('paternalLastName')"
              :disabled="rescheduleMode"
            />
          </validation-provider>
        </b-row>
        <b-row>
          <validation-provider v-slot="{ errors }" class="col-sm-12 col-md-4">
            <base-input
              v-model="newAppointmentModel.maternalLastName"
              :error="errors[0]"
              :error-msg="$t('maternalLastNameRequired')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('maternalLastName')"
              :disabled="rescheduleMode"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            :rules="{
              numeric: true,
              required: serviceConfiguration.mobilePhoneRequired,
            }"
          >
            <base-input
              v-model="newAppointmentModel.phone"
              :error="errors[0]"
              :error-msg="$t('phoneRequired')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('phone')"
              mask-type="Phone"
              :disabled="rescheduleMode"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            :rules="{
              required: serviceConfiguration.mobilePhoneRequired,
            }"
          >
            <filter-multi-select
              :error="errors[0]"
              :error-msg="$t('mustselectprovider')"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('provider')"
              :options="availableCarriers"
              :placeholder="$t('select')"
              :value="newAppointmentModel.carrier"
              :disabled="rescheduleMode"
              @change="OnCarrierChange($event)"
            />
          </validation-provider>
        </b-row>
        <b-row>
          <validation-provider
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            :rules="`required`"
          >
            <filter-numeric
              v-model="newAppointmentModel.attendees"
              :error="errors[0]"
              :error-msg="$t('mustEnterAttendees')"
              icon="fas fa-user mr-2"
              :max="newAppointmentModel.maxAttendees || 10"
              :min="1"
              title="attendees"
              :disabled="rescheduleMode"
            />
          </validation-provider>
          <validation-provider
            v-if="
              appointmentSearchModel.localization.length &&
              appointmentSearchModel.localization[0].showExtraField1 &&
              appointmentSearchModel.localization[0].extraField1Name
            "
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            :rules="{
              required:
                appointmentSearchModel.localization.length &&
                appointmentSearchModel.localization[0].showExtraField1 &&
                appointmentSearchModel.localization[0].extraField1Required &&
                !rescheduleMode,
            }"
          >
            <base-input
              v-model="newAppointmentModel.fileNumber"
              :error="errors[0]"
              :error-msg="
                appointmentSearchModel.localization.length
                  ? `${$t('mustAddField')} ${
                      appointmentSearchModel.localization[0].extraField1Name
                    }.`
                  : $t('requiredField')
              "
              fieldtext="name"
              fieldvalue="id"
              :label="
                appointmentSearchModel.localization.length
                  ? appointmentSearchModel.localization[0].extraField1Name
                  : ''
              "
              :disabled="
                rescheduleMode ||
                (filterExtraField1 &&
                  companyConfig?.filterServicesByExtraField1)
              "
            />
          </validation-provider>
          <validation-provider
            v-if="
              appointmentSearchModel.localization.length &&
              appointmentSearchModel.localization[0].showExtraField2 &&
              appointmentSearchModel.localization[0].extraField2Name
            "
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            :rules="{
              required:
                appointmentSearchModel.localization.length &&
                appointmentSearchModel.localization[0].showExtraField2 &&
                appointmentSearchModel.localization[0].extraField2Required &&
                !rescheduleMode,
            }"
          >
            <base-input
              v-model="newAppointmentModel.specialField2"
              :error="errors[0]"
              :error-msg="
                appointmentSearchModel.localization.length
                  ? `${$t('mustAddField')} ${
                      appointmentSearchModel.localization[0].extraField2Name
                    }.`
                  : $t('requiredField')
              "
              fieldtext="name"
              fieldvalue="id"
              :label="
                appointmentSearchModel.localization.length
                  ? appointmentSearchModel.localization[0].extraField2Name
                  : ''
              "
              :disabled="
                filterExtraField1 &&
                companyConfig?.fetchExtraField2ByExtraField1
              "
            />
          </validation-provider>

          <validation-provider
            v-if="showSpecialField3"
            v-slot="{ errors }"
            class="col-sm-12 col-md-4"
            :rules="{
              required:
                !!appointmentSearchModel.localization[0].extraField3Required &&
                !rescheduleMode,
            }"
          >
            <filter-select
              v-if="showSpecialField3"
              v-model="newAppointmentModel.specialField3"
              :clearable="
                !appointmentSearchModel.localization[0].extraField3Required
              "
              :error="errors[0]"
              :error-msg="$t('fieldRequired')"
              fieldtext="name"
              fieldvalue="name"
              :label="appointmentSearchModel.localization[0].extraField3Name"
              :options="specialField3Options"
              :disabled="rescheduleMode"
            />
          </validation-provider>
        </b-row>
        <b-row>
          <validation-provider
            v-if="!serviceConfiguration.hideComment"
            v-slot="{ errors }"
            class="col-sm-12 col-md-12"
            :rules="{
              required: serviceConfiguration.commentRequired,
            }"
          >
            <base-input
              v-model="newAppointmentModel.comment"
              :error="errors[0]"
              :error-msg="$t('mustwritecomment')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('comment')"
              :max-length="240"
              :textarea="true"
            />
          </validation-provider>
        </b-row>
        <template #footer>
          <b-col>
            <b-row>
              <b-col class="d-flex justify-content-center mb-4" cols="12">
                <h5>{{ $t('confirmAppointmentValidation') }}</h5>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <b-col class="d-flex justify-content-center" cols="4">
                <base-filled-button
                  bg-color="#707070"
                  class="btn-cancel"
                  icon-class="far fa-times-circle"
                  :on-click="Hide"
                  :text="$t('no')"
                />
                <base-filled-button
                  icon-class="fad fa-save"
                  :on-click="() => OnSubmitConfirmation()"
                  :text="$t('yes')"
                />
              </b-col>
            </b-row>
          </b-col>
        </template>
      </custom-modal>
      <custom-modal
        id="alreadyHasAppointment"
        ref="alreadyHasAppointment"
        size="md"
      >
        <div
          v-if="modalErrorMessage"
          class="py-4 mb-3 d-flex justify-content-center align-items-center"
        >
          <i
            class="h3 mx-3 my-0 fas fa-exclamation-circle text-danger big-icon"
          />
          <p class="m-0 text-danger font-md" v-html="modalErrorMessage" />
        </div>
        <div v-if="haveAppointment" class="text-center">
          <p class="mt-2 font-md">{{ $t('sureToConfirmAppointment') }}</p>
        </div>
        <b-row class="d-flex justify-content-center">
          <b-col
            v-if="!haveAppointment"
            class="d-flex justify-content-center"
            cols="4"
          >
            <base-filled-button
              bg-color="#707070"
              :on-click="
                () => {
                  this.$refs.alreadyHasAppointment.hide();
                }
              "
              :text="$t('continue')"
            />
          </b-col>
          <div v-else class="col-md-12 d-flex justify-content-center">
            <button
              class="btn btn-primary mr-3"
              @click="OnSubmitConfirmation(false)"
            >
              {{ $t('yes') }}
            </button>
            <button
              class="btn btn-secondary"
              @click="$refs.alreadyHasAppointment.hide()"
            >
              {{ $t('no') }}
            </button>
          </div>
        </b-row>
      </custom-modal>
    </validation-observer>
  </div>
</template>

<script>
import contentHeader from '@/components/Header';
import fullCalendar from '@/components/full-calendar/FullCalendar';
import { VIEW_TYPE_CODE } from '@/components/full-calendar/constants';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import CustomModal from '../../components/basics/modal/CustomModal.vue';
import BaseInput from '../../components/BaseInput.vue';
import BaseTimePicker from '@/components/BaseTimePicker.vue';
import { mapGetters } from 'vuex';
import AppointmentService from '@/services/AppointmentService.js';
import AlertMessage from '@/components/basics/alerts/AlertMessage.vue';
import {
  prereserveAppointment,
  getMaxAttendeesForService,
  confirmAppointment as _confirmAppointment,
  getAppointmentById as _getAppointmentById,
  rescheduleAppointment as _rescheduleAppointment,
} from '@/services/AppointmentService';
import { getProfile } from '@/services/AccountService';
import { getAllCarriers } from '@/services/CarrierService';
import { getAllServices } from '@/services/ServicesService';
import { getSubServicesByServiceQueueId } from '@/services/ServicesService';
import { getOrganizationsByService } from '@/services/OrganizationService';
import { getCompanies, getCompanyConfig } from '@/services/CompanyService';
import SpecialistService from '@/services/SpecialistService';
import {
  getLocationsForSpaceCreation as _getLocations,
  getMiscData as _getMiscData,
  canCoordinateInternalAppointments as _canCoordinateInternalAppointments,
  canCoordinateInternalAppointmentsToday as _canCoordinateInternalAppointmentsToday,
  maximumDaysToPreventAppointments as _maximumDaysToPreventAppointments,
  getExtraField2ByExtraField1 as _getExtraField2ByExtraField1,
} from '@/services/LocationService';
import { getServiceType } from '@/services/CodeService';
import CalendarTypes from '@/constants/CalendarTypes';

// This import is only used to initialize inside data/computed
import moment from 'moment';
import SearchInput from '@/components/SearchInput.vue';

export default {
  name: 'CreateAppointment',
  components: {
    contentHeader,
    FilterMultiSelect,
    fullCalendar,
    CustomModal,
    BaseInput,
    BaseTimePicker,
    AlertMessage,
    SearchInput,
  },
  props: {
    rescheduleMode: {
      type: Boolean,
      default: false,
    },
    clientAppointmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    BaseInput;
    return {
      coordinateInternalAppointments: true,
      coordinateInternalAppointmentsToday: true,
      defaultHour: moment().format(),
      availableServices: [],
      availableSubServices: [],
      availableOrganizations: [],
      availableSpecialists: [],
      availableCompanies: [],
      availableLocalizations: [],
      availableCarriers: [],

      lastSearchStartDate: null,
      lastSearchEndDate: null,

      appointmentSearchModel: this.resetSearchModel(),
      appointmentConfirmationModel: this.resetAppointmentConfirmation(),
      newAppointmentModel: this.resetNewAppointment(),
      newAppointmentModelStorage: this.resetNewAppointment(),
      serviceConfiguration: {
        id: 0,
        name: '',
        mobilePhoneRequired: true,
        emailRequired: true,
        commentRequired: false,
        hideComment: false,
      },

      confirmedAppointment: {
        clientAppointmentId: 0,
        clientId: 0,
        clientName: '',
        locationId: 0,
        locationName: '',
        locationAddressLine1: '',
        locationAddressLine2: '',
        locationAddressLine3: '',
        locationAddressZipCode: '',
        locationAddressMunicipalityName: '',
        locationAddressState: '',
        serviceTypeId: '',
        serviceTypeName: '',
        date: '',
        timeStart: '',
        timeEnd: '',
        attendees: 0,
        locationAddressGpsLatitude: '',
        locationAddressGpsLongitude: '',
        specialField1: '',
        specialField2: '',
      },

      appointmentToReschedule: {},

      showConfirmationModal: false,
      showAvailableSpaces: false,
      showService: false,

      hourTo: '',
      startDate: '',

      showResults: false,
      appointments: [],
      appointmentsMonth: [],

      modalErrorMessage: null,
      allowCoordinateOtherService: false,
      specialField3Options: [],
      haveAppointment: false,
      maxDateTime: null,

      viewType: VIEW_TYPE_CODE.WEEK,
      companyConfig: {
        filterServicesByExtraField1: false,
        fetchExtraField2ByExtraField1: false,
      },
      filterExtraField1: null,
    };
  },
  methods: {
    async handleSelectedAppointment(appointmentSummary, selectedAppointment) {
      if (selectedAppointment.isPreReserved) {
        return;
      }

      Object.assign(
        this.appointmentConfirmationModel,
        appointmentSummary,
        selectedAppointment,
      );

      if (this.allowCoordinateOtherService) {
        this.newAppointmentModel = { ...this.newAppointmentModelStorage };
      } else {
        this.newAppointmentModel = this.resetNewAppointment();
      }

      this.newAppointmentModel.clientAppointmentId =
        this.appointmentConfirmationModel.clientAppointmentId;

      await Promise.all(
        [
          await getMaxAttendeesForService(appointmentSummary.serviceId).then(
            ({ data }) => {
              if (data) {
                this.newAppointmentModel.maxAttendees = data;
              }
            },
          ),
        ],
        [
          await getServiceType(
            this.appointmentSearchModel.service[0].value,
          ).then(({ data }) => {
            this.serviceConfiguration = data;
          }),
        ],
        [
          await prereserveAppointment(
            this.newAppointmentModel.clientAppointmentId,
          )
            .then(({ data }) => {
              if (data) {
                this.SetRescheduleInfo();
                this.Show();
              }
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message)),
        ],
      );

      if (this.companyConfig?.filterServicesByExtraField1) {
        this.newAppointmentModel.fileNumber = this.filterExtraField1;
      }

      if (this.companyConfig?.fetchExtraField2ByExtraField1) {
        await _getExtraField2ByExtraField1(this.filterExtraField1)
          .then((resp) => {
            this.newAppointmentModel.specialField2 = resp.data;
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          });
      }
    },
    SetRescheduleInfo() {
      if (this.rescheduleMode && this.appointmentToReschedule) {
        this.newAppointmentModel.email =
          this.appointmentToReschedule.clientEmail;
        this.newAppointmentModel.firstName =
          this.appointmentToReschedule.clientNameFirst;
        this.newAppointmentModel.paternalLastName =
          this.appointmentToReschedule.clientNameLast;
        this.newAppointmentModel.maternalLastName =
          this.appointmentToReschedule.clientSecondLastName;
        this.newAppointmentModel.phone =
          this.appointmentToReschedule.clientTelephone;
        this.newAppointmentModel.attendees =
          this.appointmentToReschedule.attendees;
        this.newAppointmentModel.comment = this.appointmentToReschedule.comment;
        this.newAppointmentModel.specialField3 =
          this.appointmentToReschedule.extraField3;

        const carrier = this.availableCarriers.find(
          (item) =>
            item.value === this.appointmentToReschedule.clientTelephoneProvider,
        );

        this.newAppointmentModel.carrier = [carrier];
        this.newAppointmentModel.carrierId = carrier.value;
      }
    },
    resetNewAppointment() {
      return {
        clientAppointmentId: 0,
        email: '',
        firstName: '',
        paternalLastName: '',
        maternalLastName: '',
        phone: '',
        carrier: [],
        carrierId: null,
        attendees: 1,
        fileNumber: '',
        comment: '',
        documents: [],
        maxAttendees: null,
        specialField1: null,
        specialField2: null,
        specialField3: null,
      };
    },
    resetAppointmentConfirmation() {
      return {
        carrier: [],
        clientAppointmentId: 0,
        companyId: 0,
        companyName: '',
        locationId: 0,
        locationName: '',
        locationTelephoneName: '',
        specialistId: 0,
        specialistNameFull: '',
        addressType: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        municipality: '',
        zipCode: '',
        date: '',
        startHour: '',
        endHour: '',
        serviceId: '',
        serviceName: '',
        subServiceName: '',
      };
    },
    resetSearchModel() {
      return {
        service: [],
        subService: [],
        organization: [],
        specialist: [],
        company: [],
        localization: [],
        startDate: moment()
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .format(),
        endDate: moment()
          .add(1, 'M')
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .format(),
      };
    },
    resetAvailableInformation() {
      this.availableServices = [];
      this.availableOrganizations = [];
      this.availableSpecialists = [];
      this.availableCompanies = [];
      this.availableLocalizations = [];
      this.availableCarriers = [];
    },
    Show() {
      this.modalErrorMessage = null;
      this.$refs.confirmAppointmentModal.show();
    },
    Hide() {
      this.$refs.confirmAppointmentModal.hide();

      if (this.allowCoordinateOtherService) {
        this.newAppointmentModel = { ...this.newAppointmentModelStorage };
      } else {
        this.newAppointmentModel = this.resetNewAppointment();
      }
    },
    async OnServiceChange(item) {
      this.showConfirmationModal = false;
      this.HideCalendar();

      this.availableOrganizations = [];
      this.availableCompanies = [];
      this.availableLocalizations = [];
      this.availableSpecialists = [];

      this.appointmentSearchModel = this.resetSearchModel();
      this.appointmentSearchModel.service = [item];

      this.fetchSubServicesByServiceQueueId();

      await getOrganizationsByService(
        this.appointmentSearchModel.service[0].value,
      )
        .then(async (resp) => {
          this.availableOrganizations = resp.data;
          if (
            this.availableOrganizations &&
            this.availableOrganizations.length == 1
          ) {
            this.appointmentSearchModel.organization =
              this.availableOrganizations;

            await this.getCompanies();
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async fetchSubServicesByServiceQueueId() {
      const { value: serviceQueueId } = this.appointmentSearchModel.service[0];

      const { data } = await getSubServicesByServiceQueueId(serviceQueueId);

      this.availableSubServices = data;
    },
    OnSubServiceChange(item) {
      this.appointmentConfirmationModel.subServiceName = item.name;
      this.appointmentSearchModel.subService = [item];
    },
    OnOrganizationChange(item) {
      this.appointmentSearchModel.organization = [item];
      this.getCompanies();
    },
    OnSpecialistChange(item) {
      if (!item || item.length === 0) {
        this.appointmentSearchModel.specialist = [];
      } else {
        this.appointmentSearchModel.specialist = [item];
      }
    },
    async OnCompanyChange(item) {
      this.appointmentSearchModel.company = [item];
      this.getLocations();
    },
    async OnLocalizationChange(item) {
      await _canCoordinateInternalAppointments(
        item.locationConfigurationId,
      ).then(
        (response) => (this.coordinateInternalAppointments = response.data),
      );
      await _canCoordinateInternalAppointmentsToday(
        item.locationConfigurationId,
      ).then(
        (response) =>
          (this.coordinateInternalAppointmentsToday = response.data),
      );
      this.maxDateTime = null;
      await _maximumDaysToPreventAppointments(
        item.locationConfigurationId,
      ).then(({ data }) => {
        if (data) this.maxDateTime = moment().add(data, 'days').format();
      });
      this.HideCalendar();
      this.appointmentSearchModel.localization = [item];

      this.availableSpecialists = [];
      this.appointmentSearchModel.specialist = [];

      const closeTime = this.officeCloseTime.split(':');

      this.appointmentSearchModel.startDate = moment(
        moment(new Date()).format('YYYY-MM-DD') +
          'T' +
          (this.officeOpenTime || '07:00:00'),
      ).format();
      let endDate = moment().add(1, 'M').format();
      if (this.maxDateTime && endDate > this.maxDateTime)
        endDate = this.maxDateTime;
      this.appointmentSearchModel.endDate = this.setDateTime(
        endDate,
        moment()
          .set({
            hour: closeTime[0],
            minute: closeTime[1],
            second: 0,
            millisecond: 0,
          })
          .format(),
      );

      if (item && item.showExtraField3) {
        await _getMiscData(item.locationConfigurationId).then(
          (response) => (this.specialField3Options = response.data),
        );
      }

      this.allowCoordinateOtherService =
        this.appointmentSearchModel.localization[0]?.allowCoordinateOtherService;

      this.getSpecialists();
    },
    async getCompanies() {
      this.availableCompanies = [];
      this.appointmentSearchModel.company = [];

      this.availableLocalizations = [];
      this.appointmentSearchModel.localization = [];

      this.availableSpecialists = [];
      this.appointmentSearchModel.specialist = [];

      await getCompanies(this.availableOrganizations.map((o) => o.value))
        .then((resp) => {
          this.availableCompanies = resp.data;
          if (this.availableCompanies && this.availableCompanies.length == 1) {
            this.appointmentSearchModel.company = this.availableCompanies;

            this.getLocations();
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations() {
      this.availableLocalizations = [];
      this.appointmentSearchModel.localization = [];

      this.availableSpecialists = [];
      this.appointmentSearchModel.specialist = [];

      if (
        !this.appointmentSearchModel.organization ||
        this.appointmentSearchModel.organization.length == 0 ||
        !this.appointmentSearchModel.company ||
        this.appointmentSearchModel.company.length == 0
      ) {
        return;
      }

      await _getLocations(this.appointmentSearchModel.company[0].value)
        .then((resp) => {
          this.availableLocalizations = resp.data;
          if (
            this.availableLocalizations &&
            this.availableLocalizations.length == 1
          ) {
            this.OnLocalizationChange(this.availableLocalizations[0]);
          }

          if (this.rescheduleMode && this.appointmentToReschedule) {
            const location = this.availableLocalizations.find(
              (item) => item.id === this.appointmentToReschedule.locationId,
            );
            this.OnLocalizationChange(location);
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getSpecialists() {
      if (
        !this.appointmentSearchModel.company ||
        this.appointmentSearchModel.company.length == 0 ||
        !this.appointmentSearchModel.localization ||
        this.appointmentSearchModel.localization.length == 0
      ) {
        return;
      }

      await SpecialistService.getDropDownListForCreateSpace(
        this.appointmentSearchModel.company[0].value,
        0,
        0,
        this.appointmentSearchModel.localization[0].id,
      )
        .then((resp) => {
          this.availableSpecialists = resp.data;
          if (
            this.availableSpecialists &&
            this.availableSpecialists.length == 1
          ) {
            this.appointmentSearchModel.specialist = this.availableSpecialists;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    OnCarrierChange(item) {
      this.newAppointmentModel.carrier = [item];
      this.newAppointmentModel.carrierId = item.value;
    },
    onStartEndDateChanged({ startDate, endDate, viewType }) {
      this.calendarRange.beginDate = startDate;
      this.calendarRange.endDate = endDate;
      this.viewType = viewType;

      if (
        viewType == VIEW_TYPE_CODE.WEEK ||
        viewType == VIEW_TYPE_CODE.MONTH ||
        viewType == VIEW_TYPE_CODE.DAY
      ) {
        this.searchRange.beginDate = startDate;
        this.searchRange.endDate = endDate;
      }

      this.loadCalendarData();
    },
    HideCalendar() {
      this.showResults = false;
      this.appointments = [];
      this.appointmentsMonth = [];
    },
    async loadCalendarData() {
      // Validate the out of range first (min date only)
      const minDate = this.coordinateInternalAppointmentsToday
        ? moment()
        : moment().add(1, 'days');

      if (this.searchRange.beginDate < minDate)
        this.searchRange.beginDate = minDate;

      if (this.searchRange.endDate > this.lastSearchEndDate)
        this.searchRange.endDate = this.lastSearchEndDate;

      if (this.searchRange.endDate < this.lastSearchStartDate) return;

      if (this.searchRange.beginDate > this.lastSearchEndDate) return;

      const isValid = await this.$refs.formRef.validate();

      if (!isValid) {
        return;
      }

      const dateStart = moment(this.searchRange.beginDate)
        .startOf('day')
        .format();
      const dateEnd = moment(this.searchRange.endDate).endOf('day').format();

      const timeStart = this.lastSearchStartDate.format();
      const timeEnd = this.lastSearchEndDate.format();

      // If no specialist is selected then select all
      let specialists = [];
      if (
        !this.appointmentSearchModel.specialist ||
        this.appointmentSearchModel.specialist.length == 0
      ) {
        specialists = this.availableSpecialists.map((c) => c.id);
      } else {
        specialists = this.appointmentSearchModel.specialist.map((c) => c.id);
      }

      if (this.viewType == VIEW_TYPE_CODE.MONTH) {
        await AppointmentService.getAvailableAppointmentMonth(
          this.appointmentSearchModel.company[0].value,
          this.appointmentSearchModel.localization[0].id,
          this.appointmentSearchModel.service.map((s) => s.value),
          specialists,
          dateStart,
          dateEnd,
          true,
          timeStart,
          timeEnd,
        )
          .then(async (resp) => {
            this.appointmentsMonth = resp.data;
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      } else {
        await AppointmentService.getAvailableAppointment(
          this.appointmentSearchModel.company[0].value,
          this.appointmentSearchModel.localization[0].id,
          this.appointmentSearchModel.service.map((s) => s.value),
          specialists,
          dateStart,
          dateEnd,
          true,
          timeStart,
          timeEnd,
        )
          .then((response) => {
            this.appointments = response.data;
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async handleSearch(filterExtraField1) {
      this.HideCalendar();
      this.appointmentSearchModel = this.resetSearchModel();
      this.newAppointmentModelStorage = this.resetNewAppointment();
      this.$refs.formRef.reset();

      await getAllServices(null, null, filterExtraField1)
        .then((resp) => {
          this.filterExtraField1 = filterExtraField1;
          this.availableServices = resp.data;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
    async handleClear() {
      this.HideCalendar();
      this.appointmentSearchModel = this.resetSearchModel();
      this.newAppointmentModelStorage = this.resetNewAppointment();
      this.$refs.formRef.reset();

      await getAllServices()
        .then((resp) => {
          this.filterExtraField1 = null;
          this.availableServices = resp.data;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
    async OnSubmit() {
      this.lastSearchStartDate = moment(this.appointmentSearchModel.startDate);
      this.lastSearchEndDate = moment(this.appointmentSearchModel.endDate);

      await this.loadCalendarData();

      this.showConfirmationModal = false;
      this.showResults = true;
    },
    async OnSubmitConfirmation(validatePreviousCoordinated = true) {
      this.modalErrorMessage = null;
      if (this.$refs && this.$refs.alreadyHasAppointment)
        this.$refs.alreadyHasAppointment.hide();
      const isValid = await this.$refs.ModalRef.validate();

      if (!isValid) {
        return;
      }

      let data = { ...this.newAppointmentModel };
      data.attendees = parseInt(data.attendees);
      data.subServiceId = this.appointmentSearchModel.subService[0]?.id ?? null;
      data.validatePreviousCoordinated = validatePreviousCoordinated;

      if (this.rescheduleMode) {
        await this.rescheduleAppointment(data);
      } else {
        await this.confirmAppointment(data);
      }
    },
    async rescheduleAppointment(data) {
      const clientAppointmentIdToReplace =
        this.newAppointmentModel.clientAppointmentId;

      data.ClientAppointmentId =
        this.appointmentToReschedule.clientAppointmentId;
      data.clientAppointmentIdToReplace = clientAppointmentIdToReplace;

      await _rescheduleAppointment(data)
        .then((resp) => {
          this.confirmedAppointment = resp.data;

          this.showConfirmationModal = true;
          this.$refs.confirmAppointmentModal.hide();
          this.appointmentSearchModel = this.resetSearchModel();
          this.newAppointmentModelStorage = this.resetNewAppointment();

          this.$refs.formRef.reset();
          this.resetAvailableInformation();
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        });
    },
    async confirmAppointment(data) {
      await _confirmAppointment(data)
        .then((resp) => {
          this.confirmedAppointment = resp.data;

          this.showConfirmationModal = true;
          this.$refs.confirmAppointmentModal.hide();
          this.appointmentSearchModel = this.resetSearchModel();
          this.newAppointmentModelStorage = this.resetNewAppointment();

          this.availableSubServices.splice(0, this.availableSubServices.length);
          this.$refs.formRef.reset();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.modalErrorMessage =
              error.response.data.message ??
              this.$t('confirmAppointmentValidInputErrorMessage');

            if (error.response.data.message)
              this.haveAppointment =
                error.response.data.message.includes('0171');

            this.$refs.alreadyHasAppointment.show();
          } else {
            this.ShowErrorToast(error.response.data.message);
          }
        });
    },
    async searchProfile() {
      if (!this.newAppointmentModel.email) {
        return;
      }

      await getProfile(this.newAppointmentModel.email)
        .then((resp) => {
          if (resp.data && resp.data.hasProfile) {
            this.newAppointmentModel.firstName = resp.data.firstName;
            this.newAppointmentModel.paternalLastName =
              resp.data.lastPaternalName;
            this.newAppointmentModel.maternalLastName =
              resp.data.lastMaternalName;
            this.newAppointmentModel.phone = resp.data.phone;
            this.newAppointmentModel.carrier = this.availableCarriers.filter(
              (p) => {
                return p.value === resp.data.carrierId;
              },
            );
            this.newAppointmentModel.carrierId =
              this.newAppointmentModel.carrier[0]?.value;
          } else {
            // Profile data reset
            this.newAppointmentModel.firstName = '';
            this.newAppointmentModel.paternalLastName = '';
            this.newAppointmentModel.maternalLastName = '';
            this.newAppointmentModel.phone = '';
            this.newAppointmentModel.carrier = [];
            this.newAppointmentModel.carrierId = null;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    OnHourCanged(value) {
      this.hourTo = value;
    },
    OnStartDateChanged(value) {
      let time = this.appointmentSearchModel.startDate;

      this.appointmentSearchModel.startDate = this.setDateTime(value, time);

      if (
        this.appointmentSearchModel.startDate >
          this.appointmentSearchModel.endDate ||
        this.appointmentSearchModel.endDate > this.aMonthFromNow
      ) {
        this.appointmentSearchModel.endDate = this.setDateTime(
          this.aMonthFromNow,
          this.appointmentSearchModel.endDate,
        );
      }
    },
    OnEndtDateChanged(value) {
      this.appointmentSearchModel.endDate = this.setDateTime(
        value,
        this.appointmentSearchModel.endDate,
      );
    },
    OnStartTimeChanged(value) {
      this.appointmentSearchModel.startDate = this.setDateTime(
        this.appointmentSearchModel.startDate,
        value,
      );
    },
    OnEndTimeChanged(value) {
      this.appointmentSearchModel.endDate = this.setDateTime(
        this.appointmentSearchModel.endDate,
        value,
      );
    },
    setDateTime(date, time) {
      let _date = this.$moment(date);
      let _time = this.$moment(time);
      return _date.hours(_time.hours()).minutes(_time.minutes()).format();
    },
    OnNoCoordinateOtherService() {
      this.showConfirmationModal = false;
      this.HideCalendar();
    },
    OnCoordinateOtherService() {
      this.showConfirmationModal = false;
      this.HideCalendar();
      this.newAppointmentModelStorage = { ...this.newAppointmentModel };
    },
    async LoadRescheduleMode() {
      if (!this.rescheduleMode) return;

      await _getAppointmentById(this.clientAppointmentId)
        .then((response) => {
          this.appointmentToReschedule = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      if (!this.appointmentToReschedule) return;

      const service = this.availableServices.find(
        (item) => item.value === this.appointmentToReschedule.serviceTypeCd,
      );
      await this.OnServiceChange(service);

      if (this.appointmentToReschedule.stampTransactionId) {
        const subService = this.availableSubServices.find(
          (item) => item.id === this.appointmentToReschedule.stampTransactionId,
        );
        await this.OnSubServiceChange(subService);
      }
    },
    getUnformattedTime(strDate) {
      let hour = moment(strDate).hours();
      let minute = moment(strDate).minutes();
      return moment().set({
        hour: hour,
        minute: minute,
        second: 0,
        millisecond: 0,
      });
    },
  },
  computed: {
    ...mapGetters('$_user', ['fullProfile']),
    showSpecialField3() {
      return (
        this.appointmentSearchModel.localization &&
        this.appointmentSearchModel.localization.length &&
        this.appointmentSearchModel.localization[0].showExtraField3 &&
        this.appointmentSearchModel.localization[0].extraField3Name
      );
    },
    startHour() {
      return this.officeOpenTime;
    },
    endHour() {
      let hour = moment(this.appointmentSearchModel.endDate).hours();
      let minute = moment(this.appointmentSearchModel.endDate).minutes();
      return moment()
        .set({ hour: hour, minute: minute, second: 0, millisecond: 0 })
        .format();
    },
    isSubServiceFieldVisible() {
      return this.availableSubServices.length > 0;
    },
    today() {
      return moment().format();
    },
    tomorrow() {
      return moment().add(1, 'days').format();
    },
    aMonthFromNow() {
      return moment(this.appointmentSearchModel.startDate).add(1, 'M').format();
    },
    calendarRange() {
      return {
        beginDate: moment(this.today).startOf('isoWeek').toDate(),
        endDate: moment(this.today).endOf('isoWeek').toDate(),
      };
    },
    searchRange() {
      return {
        beginDate: moment(this.today).startOf('isoWeek').toDate(),
        endDate: moment(this.today).endOf('isoWeek').toDate(),
      };
    },
    officeOpenTime() {
      if (
        this.appointmentSearchModel.localization &&
        this.appointmentSearchModel.localization.length > 0
      ) {
        let officeOpenTime = { hours: 7, minutes: 0 };

        if (this.appointmentSearchModel.localization[0].officeOpenTime) {
          const values =
            this.appointmentSearchModel.localization[0].officeOpenTime.split(
              ':',
            );

          officeOpenTime = {
            hours: values[0],
            minutes: values[1],
          };
        }

        const result = moment({
          hour: officeOpenTime.hours,
          minute: officeOpenTime.minutes,
        }).format('HH:mm:ss');

        return result;
      }
      return null;
    },
    officeCloseTime() {
      if (
        this.appointmentSearchModel.localization &&
        this.appointmentSearchModel.localization.length > 0
      ) {
        let officeCloseTime = { hours: 19, minutes: 0 };

        if (this.appointmentSearchModel.localization[0].officeCloseTime) {
          const values =
            this.appointmentSearchModel.localization[0].officeCloseTime.split(
              ':',
            );

          officeCloseTime = {
            hours: values[0],
            minutes: values[1],
          };
        }

        const result = moment({
          hour: officeCloseTime.hours,
          minute: officeCloseTime.minutes,
        }).format('HH:mm:ss');

        return result;
      }
      return null;
    },
    disableSearch() {
      return (
        !this.appointmentSearchModel.service ||
        this.appointmentSearchModel.service.length === 0 ||
        !this.appointmentSearchModel.organization ||
        this.appointmentSearchModel.organization.length === 0 ||
        !this.appointmentSearchModel.company ||
        this.appointmentSearchModel.company.length === 0 ||
        !this.appointmentSearchModel.localization ||
        this.appointmentSearchModel.localization.length == 0
      );
    },
    getSchedule() {
      return `${moment(this.confirmedAppointment.timeStart).format(
        'hh:mm A',
      )} - ${moment(this.confirmedAppointment.timeEnd).format('hh:mm A')}`;
    },
    getAddress() {
      return `${
        this.confirmedAppointment.locationAddressLine1
          ? this.confirmedAppointment.locationAddressLine1 + ', '
          : ''
      }
      ${
        this.confirmedAppointment.locationAddressLine2
          ? this.confirmedAppointment.locationAddressLine2 + ', '
          : ''
      }
      ${
        this.confirmedAppointment.locationAddressLine3
          ? this.confirmedAppointment.locationAddressLine3 + ', '
          : ''
      }
      ${
        this.confirmedAppointment.locationAddressMunicipalityName
          ? this.confirmedAppointment.locationAddressMunicipalityName + ', '
          : ''
      }
      ${
        this.confirmedAppointment.locationAddressState
          ? this.confirmedAppointment.locationAddressState + ', '
          : ''
      }
      ${
        this.confirmedAppointment.locationAddressZipCode
          ? this.confirmedAppointment.locationAddressZipCode + ', '
          : ''
      }`;
    },
    date() {
      return moment(this.confirmedAppointment.date).format(
        'dddd / DD MMMM / YYYY',
      );
    },
    calendarType() {
      return CalendarTypes.RegisteredAppointments;
    },
  },
  async mounted() {
    await Promise.all(
      [
        getAllServices()
          .then((resp) => {
            this.availableServices = resp.data;
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          }),
      ],
      [
        getAllCarriers()
          .then((resp) => {
            this.availableCarriers = resp.data;
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          }),
      ],
      [
        getCompanyConfig(this.fullProfile.companyId)
          .then((resp) => {
            this.companyConfig = resp.data;
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          }),
      ],
    );

    await this.LoadRescheduleMode();
  },
};
</script>

<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
#appointments-container {
  background-color: $color-app-background;
  min-height: 100vh;
}
.main-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 23px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
.AppointmentConfirmation-Success {
  padding: 1rem;
  background-color: $color-success;
  width: 100%;
  border-radius: 5px;
  i {
    font-size: 3.5rem;
    margin: 0 2rem;
    color: $color-font-success;
  }
  p {
    margin: 0;
    color: $color-font-success;
    font-size: 16px;
    font-weight: lighter;
    line-height: 35px;
  }
}
.AppointmentConfirmation-Info {
  span:first-child {
    color: $color-font-secondary;
  }
}
#confirmAppointmentModal {
  h6 {
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
  }
  h5 {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    span:first-child {
      font-size: 16px;
      font-weight: bold;
      color: $color-font-primary;
    }
  }
}
.searchIcon {
  margin: 0 10px;
  font-size: 18px;
  color: $color-primary;
  cursor: pointer;
}
.specialist-name {
  color: $color-primary;
  font-weight: bold;
}

.btn-cancel {
  background-color: $color-font-secondary;
}

.btn-cancel:hover {
  background-color: $color-font-primary;
}
</style>
