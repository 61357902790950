<template>
  <b-row>
    <b-col cols="12">
      <small v-if="this.appointmentId">{{
        `${$t('appointment')} ${appointmentId}`
      }}</small>
      <div class="mt-2 mb-4 text-center text-uppercase text-primary">
        <h4 class="font-weight-bolder">
          {{ $t('document(s)') }}
        </h4>
      </div>
    </b-col>
    <b-col>
      <loading
        :active="loading"
        color="#F37E00"
        :is-full-page="false"
        :z-index="50"
      />
      <b-table :fields="fields" :items="documents" responsive>
        <template #cell(source)="data">
          {{ $t(isTurnSource(data.item.source) ? 'turn' : 'appointment') }}
        </template>
        <template #cell(action)="data">
          <b-button
            :disabled="noAction"
            class="float-left text-capitalize"
            :title="data.item.fileName"
            variant="primary"
            @click="onDownload(data.item)"
          >
            {{ $t('see') }}
          </b-button>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12">
      <b-button
        class="float-right text-capitalize"
        variant="secondary"
        @click="$emit('close')"
      >
        {{ $t('close') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { getDocuments as _getDocuments } from '@/services/AppointmentService';
import { getDocumentsZip as _getDocumentsZip } from '@/services/AppointmentService';
import { getDocument as _getDocument } from '@/services/AppointmentService';
import { getDocuments as _getTurnDocuments } from '@/services/TurnService';
import { getDocumentsZip as _getTurnDocumentsZip } from '@/services/TurnService';
import { getDocument as _getTurnDocument } from '@/services/TurnService';
import _generalApi from '@/modules/app/api.js';

export default {
  name: 'AppointmentDocuments',
  props: {
    appointmentId: {
      type: Number,
      default: () => null,
    },
    turnoId: {
      type: Number,
      default: () => null,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'source',
          label: this.$t('source'),
        },
        {
          key: 'documentTypeName',
          label: this.$t('documentType'),
        },
        {
          key: 'action',
          label: this.$t('action'),
        },
      ],
      documents: [],
      loading: false,
    };
  },
  async mounted() {
    try {
      this.loading = true;
      await _getTurnDocuments(this.turnoId)
        .then(
          (result) =>
            (this.documents = this.documents.concat(
              result.data.map((x) => ({ ...x, source: 1 })),
            )),
        )
        .catch((error) => this.ShowErrorToast(error.response.data.message));
      if (this.appointmentId) {
        await _getDocuments(this.appointmentId)
          .then(
            (result) =>
              (this.documents = this.documents.concat(result.data.map((x) => ({ ...x, source: 2 })))),
          )
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    isTurnSource(source) {
      return source === 1;
    },
    onDownloadAll() {
      if (this.appointmentId) {
        this.loading = true;
        _getDocumentsZip(this.appointmentId)
          .then((response) => _generalApi.ConvertToBlob(response.data))
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          })
          .finally(() => (this.loading = false));
      } else if (this.turnoId) {
        this.loading = true;
        _getTurnDocumentsZip(this.turnoId)
          .then((response) => _generalApi.ConvertToBlob(response.data))
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    onDownload(document) {
      if (document.source == 2) {
        this.loading = true;
        _getDocument(this.appointmentId, document.documentId)
          .then((response) => _generalApi.ConvertToBlobToOpen(response.data))
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          })
          .finally(() => (this.loading = false));
      } else if (document.source == 1) {
        this.loading = true;
        _getTurnDocument(this.turnoId, document.documentId)
          .then((response) => _generalApi.ConvertToBlobToOpen(response.data))
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
