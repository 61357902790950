var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('content-header',{attrs:{"title":_vm.$t('registeredAppointments')}}),_c('div',{staticClass:"container-fluid"},[_c('b-container',{staticClass:"main-page-container",attrs:{"fluid":""}},[_c('b-row',[_c('sub-header',{attrs:{"title":_vm.$t('filter')}})],1),_c('filter-manager',{ref:"filterComponent",staticClass:"mb-4",attrs:{"display-advanced":false,"filters":_vm.filtersConfig,"search-text":_vm.$t('search')},on:{"search":function($event){return _vm.getAppointments(1)}},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}})],1),(!_vm.isDateValid)?_c('alert-message',{attrs:{"icon-class":"far fa-times-circle","message":_vm.$t(
          this.selectedFilters.datefrom > this.selectedFilters.dateto
            ? 'cantDoSearchGreaterThanDays'
            : 'cantDoSearchHigherThanDays',
        )}}):_vm._e(),(_vm.noResultsFound)?_c('CustomNotFound',{attrs:{"text":_vm.$t('noResultsFound')}}):_vm._e(),(_vm.registeredAppoinments.data.length)?_c('separator',{staticClass:"my-3",attrs:{"text":_vm.$t('registeredAppointments')}}):_vm._e(),_vm._l((_vm.registeredAppoinments.data),function(result){return _c('search-result',{key:result.clientAppointmentId,attrs:{"value":result},on:{"cancel":function($event){return _vm.OpenCancelConfirmation(result.clientAppointmentId)},"complete":function($event){return _vm.OnComplete(result.clientAppointmentId)},"edit":function($event){return _vm.onEdit(result.clientAppointmentId)},"send-appointment-confirmation":function($event){return _vm.openSendAppointmentConfirmationModal(result.clientAppointmentId)},"show-documents":function($event){return _vm.openAppointmentDocumentsModal(result.clientAppointmentId)},"show-stamps":function($event){return _vm.openAppointmentStampsModal(result.clientAppointmentId)},"reschedule-appointment":function($event){return _vm.onRescheduleAppointment(
          result.clientAppointmentId,
          result.locationConfigurationId,
        )}}})}),(_vm.registeredAppoinments.count > 0)?_c('custom-pagination',{attrs:{"id":"pgPager","data":_vm.registeredAppoinments,"page-size":_vm.pageSize},on:{"pagination-go-page":function($event){return _vm.getAppointments($event)},"pagination-rows-per-page":function($event){return _vm.getAppointments(_vm.pageIndex, $event)}}}):_vm._e(),_c('custom-modal',{ref:"appointment-stamps-ref",attrs:{"id":"appointment-stamps-modal","size":"md"}},[_c('appointment-stamps',{attrs:{"appointment-id":_vm.selectedAppointmentId,"stamps-info":_vm.appointmentStampsInfo},on:{"close":function($event){return _vm.$refs['appointment-stamps-ref'].hide()}}})],1),_c('custom-modal',{ref:"appointment-documents-ref",attrs:{"id":"appointment-documents-modal","size":"md"}},[_c('appointment-documents',{attrs:{"appointment-id":_vm.selectedAppointmentId},on:{"close":function($event){return _vm.$refs['appointment-documents-ref'].hide()}}})],1),_c('custom-modal',{ref:"appointment-edit-ref",attrs:{"id":"appointment-edit-modal","size":"lg"}},[_c('edit-appointment',{attrs:{"client-appointment-id":_vm.selectedAppointmentId},on:{"cancel":() => {
            _vm.$refs['appointment-edit-ref'].hide();
          },"save":() => {
            _vm.$refs['appointment-edit-ref'].hide();
            _vm.getAppointments();
          }}})],1),_c('base-alert',{attrs:{"message":_vm.$t('cancelAppointmentConfimation'),"title":_vm._f("uppercase")(_vm.$t('cancelAppointment'))},on:{"on-confirm":function($event){return _vm.OnCancel($event)}},model:{value:(_vm.showCancelModal),callback:function ($$v) {_vm.showCancelModal=$$v},expression:"showCancelModal"}}),_c('base-alert',{attrs:{"message":_vm.$t('sendAppointmentConfirmation'),"title":_vm._f("uppercase")(_vm.$t('resend'))},on:{"on-confirm":function($event){return _vm.onSendAppointmentConfirmation($event)}},model:{value:(_vm.showSendAppointmentConfirmationModal),callback:function ($$v) {_vm.showSendAppointmentConfirmationModal=$$v},expression:"showSendAppointmentConfirmationModal"}})],2),_c('custom-modal',{ref:"customAlert",attrs:{"id":"customAlert","size":"md"}},[_c('AlertMessage',{attrs:{"icon-class":"fas fa-exclamation-circle","message":_vm.$t('previousAppointmentFound')}}),_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"4"}},[_c('base-filled-button',{attrs:{"bg-color":"#707070","on-click":() => {
              this.$refs.customAlert.hide();
            },"text":_vm.$t('continue')}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }