<template>
  <div class="search-input">
    <div class="d-inline-flex align-middle">
      <base-input
        :id="id"
        v-model="value"
        :placeholder="placeholder"
        :maxLength="50"
        :disabled="disabled"
        :mask="mask"
        :maskType="MaskType.Custom"
      />
    </div>
    <div class="d-inline-flex align-middle">
      <base-filled-button
        class="button mr-0"
        :icon-class="this.disabled ? 'far fa-times' : 'far fa-search-dollar'"
        :on-click="onClick"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import MaskType from '@/constants/MaskType';

export default {
  name: 'SearchInput',
  components: {
    BaseInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    mask: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      value: '',
      disabled: false,
    };
  },
  methods: {
    onClick() {
      if (this.disabled) {
        this.clear();
      } else if (this.value.length > 0) {
        this.disabled = true;
        this.$emit('click', this.value);
      }
    },
    clear() {
      this.value = '';
      this.disabled = false;
      this.$emit('clear');
    },
  },
  computed: {
    MaskType() {
      return MaskType;
    },
  },
};
</script>

<style>
.search-input .c-s-filterMultiSelect {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.search-input .form-group {
  margin-bottom: 0 !important;
}
.search-input button {
  min-width: 0 !important;
  padding-left: 10px !important;
  padding-right: 5px !important;
  margin-left: 0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 39px !important;
}
</style>
