<template>
  <validation-observer
    ref="formRef"
    novalidate
  >
    <b-row>
      <b-col
        class="d-flex justify-content-center my-4"
        cols="12"
      >
        <h6>{{ $t('editAppointmentTitle') }}</h6>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center align-items-center px-5 pb-3">
      <label class="col-3 h5">{{ $t('appointmentNumber') }}:</label>
      <label class="col-5 h5 px-4">{{ client.clientAppointmentId }}</label>
    </b-row>
    <b-row class="d-flex justify-content-center align-items-center px-5">
      <label class="col-3 h5">{{ $t('name') }}:</label>
      <validation-provider
        v-slot="{ errors }"
        class="col-5"
        rules="required"
      >
        <base-input
          v-model="client.clientNameFull"
          :disabled="true"
          :error="errors[0]"
          :error-msg="$t('mustEnterName')"
        />
      </validation-provider>
    </b-row>
    <b-row class="d-flex justify-content-center align-items-center px-5">
      <label class="col-3 h5">{{ $t('email') }}:</label>
      <validation-provider
        class="col-5"
      >
        <base-input
          v-model="client.clientEmail"
          :disabled="true"
        />
      </validation-provider>
    </b-row>
    <b-row class="d-flex justify-content-center align-items-center px-5">
      <label class="col-3 h5">{{ $t('phone') }}:</label>
      <validation-provider
        v-slot="{ errors }"
        class="col-5"
        rules="required|numeric"
      >
        <base-input
          v-model="client.clientTelephone"
          :error="errors[0]"
          :error-msg="'Debe escribir su número telefónico'"
          mask-type="Phone"
        />
      </validation-provider>
    </b-row>
    <b-row class="d-flex justify-content-center align-items-center px-5">
      <label class="col-3 h5">{{ $t('provider') }}:</label>
      <validation-provider
        v-slot="{ errors }"
        class="col-5"
        rules="required"
      >
        <filter-multi-select
          :error="errors[0]"
          :error-msg="$t('mustselectcompany')"
          fieldtext="text"
          fieldvalue="value"
          :options="availableCarriers"
          :placeholder="$t('select')"
          :value="client.provider"
          @change="OnProviderChange($event)"
        />
      </validation-provider>
    </b-row>
    <b-row class="d-flex justify-content-between px-3 py-4">
      <base-filled-button
        bg-color="#707070"
        icon-class="far fa-times"
        :on-click="() => this.$emit('cancel')"
        :text="$t('cancel')"
      />
      <base-filled-button
        icon-class="far fa-save"
        :on-click="EditClient"
        :text="$t('save')"
      />
    </b-row>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import { getAllCarriers } from '@/services/CarrierService';
import { getAppointmentById } from '@/services/AppointmentService';
import { updateProfile } from '@/services/AccountService';

export default {
  name: 'EditAppointment',
  components: {
    BaseInput,
    FilterMultiSelect,
  },
  props: {
    clientAppointmentId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      client: {
        clientAppointmentId: '',
        clientId: 0,
        clientNameFull: '',
        clientEmail: '',
        clientTelephone: '',
        clientTelephoneProvider: 0,
        provider: [],
      },
      availableCarriers: [],
    };
  },
  async mounted() {
    await getAllCarriers()
      .then((resp) => {
        this.availableCarriers = resp.data;
      })
      .catch(error => this.ShowErrorToast(error.response.data.message));

    await getAppointmentById(this.clientAppointmentId)
      .then((resp) => {
        let {
          clientId,
          clientAppointmentId,
          clientNameFull,
          clientTelephone,
          clientTelephoneProvider,
          clientEmail,
        } = resp.data;

        this.client = {
          clientId,
          clientAppointmentId: String(clientAppointmentId),
          clientNameFull,
          clientTelephone,
          clientTelephoneProvider,
          clientEmail,
          provider: this.availableCarriers.filter(
            (p) => p.value === clientTelephoneProvider,
          ),
        };
      })
      .catch(error => this.ShowErrorToast(error.response.data.message));
  },
  methods: {
    async EditClient() {
      const isValid = await this.$refs.formRef.validate();
      if (!isValid) {
        return;
      }

      await updateProfile({
        clientId: this.client.clientId,
        phone: this.client.clientTelephone,
        carrierId: this.client.clientTelephoneProvider,
        clientAppointmentId: this.clientAppointmentId,
      })
        .then(() => {
          this.$emit('save');
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    OnProviderChange(item) {
      this.client.provider = [item];
      this.client.clientTelephoneProvider = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
#appointment-edit-modal {
  margin-bottom: 0;
  h6 {
    color: $color-primary;
  }
}
::v-deep label {
  color: $color-font-secondary !important;
  font-size: 20px;
}
</style>
