<template>
  <b-row>
    <b-col cols="12">
      <span>{{ `${$t('appointment')} ${appointmentId}` }}</span>
      <div class="mt-2 mb-4 text-center text-uppercase text-primary">
        <h4 class="font-weight-bolder">
          {{ $t('stamps') }}
        </h4>
      </div>
    </b-col>

    <b-col cols="12">
      <div
        v-if="stampsInfoData.isValid == false"
        class="alert alert-warning text-uppercase"
        role="alert"
      >
        {{
          $t('totalNumberActiveStampsIsNotSufficient')
            .replace('{0}', stampsInfoData.serviceName)
            .replace('{1}', stampsInfoData.amountTransaction)
        }}
      </div>
    </b-col>

    <b-col>
      <b-table :fields="fields" :items="stamps" responsive>
        <template #cell(status)="data">
          <span
            :class="`text-${
              status.active == data.item.status ? 'success' : 'danger'
            }`"
            >{{ data.item.status }}</span
          >
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <div
        v-if="stampsInfoData.isValid == false"
        class="alert alert-warning text-uppercase"
        role="alert"
      >
        {{ $t('contactDigitalCollectionAssistance') }}
      </div>
    </b-col>
    <b-col cols="12">
      <b-button
        v-if="showAction"
        class="float-right text-capitalize ml-2"
        variant="primary"
        @click="onAction"
      >
        {{ textAction }}
      </b-button>
      <b-button
        class="float-right text-capitalize"
        variant="secondary"
        @click="$emit('close')"
      >
        {{ $t('close') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { getStamps as _getStamps } from '@/services/AppointmentService';
import StampPaidStatus from '@/constants/status/StampPaidStatus';

export default {
  name: 'AppointmentStamps',
  props: {
    appointmentId: {
      type: Number,
      default: () => null,
    },
    stampsInfo: {
      type: Object,
      default: () => null,
    },
    textAction: {
      type: String,
      default: () => '',
    },
    showAction: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'stampNumber',
          label: this.$t('number'),
        },
        {
          key: 'name',
          label: this.$t('name'),
        },
        {
          key: 'receiptNumber',
          label: this.$t('receiptNumber'),
        },
        {
          key: 'status',
          label: this.$t('status'),
        },
        {
          key: 'value',
          label: this.$t('value'),
        },
      ],
      stamps: [],
      status: StampPaidStatus,
      stampsInfoData: this.stampsInfo,
    };
  },
  methods: {
    onAction() {
      this.$emit('on-action');
      this.$emit('close');
    },
  },
  async mounted() {
    if (!this.appointmentId) return;

    if (this.stampsInfoData == null) {
      await _getStamps(this.appointmentId)
        .then((result) => {
          this.stampsInfoData = result.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    }

    this.stamps = this.stampsInfoData.appointmentPaidStamps.map((stamp) => {
      return {
        ...stamp,
        value: `$${parseFloat(stamp.value).toFixed(2)}`,
      };
    });
  },
};
</script>
