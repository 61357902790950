const ClientAppointmentStatus = Object.freeze({
  Coordinated: 1,
  Cancelled: 3,
  Completed: 4,
  Approved: 5,
  Denied: 6,
  Extended: 8,
  Confirmed: 9,
  Registered: 13,
  Incomplete: 14,
  Remembered: 16,
  NotPresent: 17,
});

export default ClientAppointmentStatus;
