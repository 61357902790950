<template>
  <section>
    <content-header
      :title="$t('createAppointment')"
    />
    <validation-observer ref="formRef" novalidate>
      <div class="container-fluid mb-2">
        <div class="main-page-container">
          <div class="row">
            <sub-header :title="$t('specialistInformation')" />
          </div>
          <section class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectcompany')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('company')"
                :options="availableCompanies"
                :placeholder="$t('select')"
                :value="model.selectedCompany"
                @change="onCompanyChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectlocalization')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('localization')"
                :options="availableLocations"
                :placeholder="$t('select')"
                :value="model.selectedLocation"
                @change="onLocationChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustSelectService')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('service')"
                :options="availableServices"
                :placeholder="$t('select')"
                :value="model.selectedService"
                @change="onServiceChanged($event)"
              />
            </validation-provider>
            <validation-provider 
              v-slot="{ errors }" 
              class="col-sm-12 col-md-4 col-lg-3" 
              rules="required">
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectspecialist')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('specialist')"
                :options="availableSpecialists"
                :placeholder="`-- ${$t('select')} --`"
                :value="model.selectedSpecialist"
                @change="onSpecialistChange($event)"
              />
            </validation-provider>
          </section>
        </div>
      </div>
      <div class="container-fluid mb-2">
        <div class="main-page-container">
          <div class="row">
            <sub-header :title="$t('citizenInformation')" />
          </div>
          <section class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3 d-flex align-items-center"
              :rules="{
                email: true,
                required: serviceConfiguration.emailRequired,
              }"
            >
              <base-input
                v-model="model.email"
                class="w-100"
                :error="errors[0]"
                :error-msg="$t('mustEnterEmail')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('email')"
                @on-enter="searchProfile"
              />
              <i class="searchIcon fal fa-search" @click="searchProfile" />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              rules="required"
            >
              <base-input
                v-model="model.firstName"
                :error="errors[0]"
                :error-msg="$t('mustEnterName')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('name')"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              rules="required"
            >
              <base-input
                v-model="model.paternalLastName"
                :error="errors[0]"
                :error-msg="$t('paternalLastNameRequired')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('paternalLastName')"
              />
            </validation-provider>
            <validation-provider v-slot="{ errors }" class="col-sm-12 col-md-4 col-lg-3">
              <base-input
                v-model="model.maternalLastName"
                :error="errors[0]"
                :error-msg="$t('maternalLastNameRequired')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('maternalLastName')"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              :rules="{
                numeric: true,
                required: serviceConfiguration.mobilePhoneRequired,
              }"
            >
              <base-input
                v-model="model.phone"
                :error="errors[0]"
                :error-msg="$t('phoneRequired')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('phone')"
                mask-type="Phone"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              :rules="{
                required: serviceConfiguration.mobilePhoneRequired,
              }"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectprovider')"
                fieldtext="text"
                fieldvalue="value"
                :label="$t('provider')"
                :options="availableCarriers"
                :placeholder="$t('select')"
                :value="model.selectedCarrier"
                @change="OnCarrierChange($event)"
                :clearable="true"
              />
            </validation-provider>
          </section>
          <section class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.attendees"
                :error="errors[0]"
                :error-msg="$t('mustEnterAttendees')"
                icon="fas fa-user mr-2"
                :max="model.maxAttendees || 10"
                :min="1"
                title="attendees"
              />
            </validation-provider>
            <validation-provider
              v-if="
                model.selectedLocation.length &&
                model.selectedLocation[0].showExtraField1 &&
                model.selectedLocation[0].extraField1Name
              "
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="{
                required:
                  model.selectedLocation.length &&
                  model.selectedLocation[0].showExtraField1 &&
                  model.selectedLocation[0].extraField1Required,
              }"
            >
              <base-input
                v-model="model.fileNumber"
                :error="errors[0]"
                :error-msg="
                  model.selectedLocation.length
                    ? `${$t('mustAddField')} ${
                        model.selectedLocation[0].extraField1Name
                      }.`
                    : $t('requiredField')
                "
                fieldtext="name"
                fieldvalue="id"
                :label="
                  model.selectedLocation.length
                    ? model.selectedLocation[0].extraField1Name
                    : ''
                "
              />
            </validation-provider>
            <validation-provider
              v-if="showSpecialField3"
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="{
                required: !!model.selectedLocation[0].extraField3Required,
              }"
            >
            <filter-select
                v-if="showSpecialField3"
                v-model="model.specialField3"
                :clearable="!model.selectedLocation[0].extraField3Required"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                fieldtext="name"
                fieldvalue="name"
                :label="model.selectedLocation[0].extraField3Name"
                :options="specialField3Options"
              />
            </validation-provider>
          </section>
          <section class="row">
            <validation-provider
              v-if="!serviceConfiguration.hideComment"
              v-slot="{ errors }"
              class="col-sm-12 col-md-12"
              :rules="{
                required: serviceConfiguration.commentRequired,
              }"
            >
              <base-input
                v-model="model.comment"
                :error="errors[0]"
                :error-msg="$t('mustwritecomment')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('comment')"
                :max-length="240"
                :textarea="true"
              />
            </validation-provider>
          </section>
        </div>
      </div>
      <div v-if="this.model.specialistLocationId" class="container-fluid">
        <div class="main-page-container">
          <div class="row">
            <sub-header :title="$t('schedule')" />
          </div>
          <section class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4 col-lg-3"
              rules="required"
            >
              <base-datetime-picker
                :error="errors[0]"
                :error-msg="$t('mustselectdate')"
                :value="model.startDate"
                format="dd/MMM/yyyy"
                :min-datetime="today"
                :title="$t('startDate')"
                type="date"
                @change="onStartDateChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-6 col-md-8"
              rules="required"
            >
              <base-time-picker
                :error="errors[0]"
                :error-msg="$t('mustselecttime')"
                icon="clock"
                :max-datetime="maxTimeFromHour"
                :min-datetime="minTimeFromHour"
                :minute-step="1"
                :required="true"
                :title="$t('hour')"
                :value="model.timeStart"
                class="c-timePicker"
                @input="onHourFromChanged"
              />
              <filter-numeric
                v-model="duration"
                class="col-12 col-xl-2 duration"
                :clearable="false"
                icon="clock"
                :max="maxDurationTime"
                :min="1"
                title="durationInMinutes"
                @input="onDurationChanged"
              />
              <b-button
                class="text-capitalize d-inline availability"
                variant="outline-success"
                @click="searchAvailability()"
              >
                <i class="fal fa-calendar-alt" /> {{ $t('availability') }}
              </b-button>
            </validation-provider>
          </section>
          <section class="row">
            <div div class="col-md-12 my-2 text-right">
              <base-filled-button
                bg-color="#707070"
                class="mx-0 float-left ml-3"
                icon-class="fas fa-ban"
                :on-click="() => $router.push({ name: 'directAppointmentsSearch' })"
                :text="$t('cancel')"
              />

              <button
                class="btn btn-primary mr-3"
                variant="primary"
                @click="onSubmit()"
              >
                <i class="far fa-save" /> {{ $t('create') }}
              </button>
            </div>
          </section>
        </div>
      </div>
    </validation-observer>
    <custom-modal
      v-model="showAvailabilityModal"
      :title="`${$t('availabilityOf')} ${model.selectedSpecialist.length ? model.selectedSpecialist[0].name : ''}`"
      size="xxl"
    >
      <full-calendar
        v-if="showAvailabilityModal"
        id="appointment-calendar"
        ref="appointment-calendar"
        :time-click="onSpaceSelected"
        :show-calendar-view-selector="false"
        :first-date="this.calendarRange.beginDate"
        :items="specialistAppointments"
        :last-date="this.calendarRange.endDate"
        :type="calendarType"
        @on-start-end-date-changed="onStartEndDateChanged"
        :show-event-item-count="false"
      />
    </custom-modal>
  </section>
</template>

<script>
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

import BaseInput from '@/components/BaseInput';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import CustomModal from '@/components/basics/modal/CustomModal.vue';

import {
  getInitialFiltersData as _getInitialFiltersData,
  getAvailablesSpecialistForAppointments as _getAvailablesSpecialistForAppointments,
  getAvailableFreeSpaces as _getAvailableFreeSpaces,
  createDirectAppointment as _createDirectAppointment
} from '@/services/AppointmentService';

import { getLocationsForSpaceCreation as _getLocationsForSpaceCreation, getMiscData as _getMiscData } from '@/services/LocationService';
import { getServiceTypesForSpaceCreation as _getServiceTypesForSpaceCreation } from '@/services/ServicesService';
import { getServiceType } from '@/services/CodeService';
import { getAllCarriers } from '@/services/CarrierService';
import { getProfile } from '@/services/AccountService';

import ContentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';

import moment from 'moment';

import fullCalendar from '@/components/full-calendar/FullCalendar';

import CalendarTypes from '@/constants/CalendarTypes';

export default {
  name: 'CreateDirectAppointment',
  components: {
    BaseInput,
    FilterMultiSelect,
    CustomModal,
    ContentHeader,
    SubHeader,
    fullCalendar
  },
  props: {

  },
  data() {
    return {
      specialistAppointments: [],
      calendarRange: {
        beginDate: this.$moment(this.currentDate).startOf('isoWeek').toDate(),
        endDate: this.$moment(this.currentDate).endOf('isoWeek').toDate(),
      },
      showAvailabilityModal: false,
      serviceConfiguration: {
        id: 0,
        name: '',
        mobilePhoneRequired: true,
        emailRequired: true,
        commentRequired: false,
        hideComment: false,
      },

      model: {
        email: '',
        firstName: '',
        paternalLastName: '',
        maternalLastName: '',
        phone: '',
        selectedCarrier: [],
        carrierId: null,
        attendees: 1,
        fileNumber: '',
        comment: '',
        documents: [],
        maxAttendees: null,
        specialField3: null,

        selectedCompany: [],
        companyId: null,

        selectedLocation: [],
        locationId: null,

        selectedService: [],
        serviceId: null,

        selectedSpecialist: [],
        specialistLocationId: null,

        startDate: null,

        timeStart: null,
        timeEnd: null,

        createdDirectly: true
      },

      availableCompanies: [],
      availableLocations: [],
      availableServices: [],
      availableSpecialists: [],
      availableCarriers: [],

      officeOpenTime: null,
      officeCloseTime: null,

      duration: 30,

      specialField3Options: []
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    maxDurationTime() {
      if (this.officeCloseTime && this.officeOpenTime) {
        return this.$moment(this.officeCloseTime).diff(
          this.officeOpenTime,
          'minutes',
        );
      }
      return 480;
    },
    isCompanyDisabled () {
      const result = this.model.selectedCompany.some(x => x.disabled);

      return result;
    },
    isLocationDisabled () {
      const result = this.model.selectedLocation.some(x => x.disabled);

      return result;
    },
    showSpecialField3() {
      return this.model.selectedLocation &&
             this.model.selectedLocation.length &&
             this.model.selectedLocation[0].showExtraField3 &&
             this.model.selectedLocation[0].extraField3Name
    },
    today() {
      return moment().format();
    },
    maxTimeFromHour() {
      if (!this.officeCloseTime) return null;

      return this.$moment(this.officeCloseTime, 'YYYY-MM-DDTHH:mm:ss')
        .subtract(this.duration, 'minutes').format('HH:mm:ss');
    },
    minTimeFromHour() {
      if (!this.officeOpenTime) return null;

      return this.$moment(this.officeOpenTime, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss');
    },
    maxTimeHoursTo() {
      if (!this.officeCloseTime) return null;

      return this.$moment(this.officeCloseTime, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss');
    },
    minTimeHoursTo() {
      const mht = this.$moment(this.model.timeStart)
      .set({ second: 0})
      .add({minutes: this.duration});

      return mht.format('HH:mm:ss');
    },
    calendarType() {
      return CalendarTypes.RegisteredAppointments;
    },
  },
  watch: {
    'model.timeStart'(newValue) {
      const mht = this.$moment(newValue, "YYYY-MM-DDTHH:mm:ss")
        .set({ second: 0})
        .add({minutes: this.duration});

      this.model.timeEnd = mht.format();
    },
    officeOpenTime(newValue) {
      if (newValue != null) {
        this.model.timeStart = newValue;
      }
    },
    showAvailabilityModal(newValue) {
      if (!newValue)
      {
        this.specialistAppointments = [];
        return;
      }

      _getAvailableFreeSpaces({
        companyId: this.model.companyId,
        locationId: this.model.locationId,
        serviceId: this.model.serviceId,
        specialistId: this.model.specialistLocationId,
        dateStart: this.$moment(this.model.timeStart).isoWeekday(1).startOf('day').format(),
        dateEnd: this.$moment(this.model.timeStart).isoWeekday(7).endOf('day').format()
      })
        .then((response) => {
          this.specialistAppointments = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    }
  },
  async mounted() {
    await this.loadInitialFilters();
  },
  methods: {
    async loadInitialFilters() {
      await _getInitialFiltersData()
        .then(async (response) => {
          const collections = response.data.collections;
          const selectedValues = response.data.filters;

          this.availableCompanies = collections.companies || [];
          this.availableLocations = collections.localizations || [];
          this.availableServices = collections.services || [];

          if(selectedValues.company){
            this.model.selectedCompany = [selectedValues.company];
            this.model.companyId = selectedValues.company.id;
          }

          if (selectedValues.localization) {
            this.model.selectedLocation = [selectedValues.localization];

            await this.onLocationChanged(this.model.selectedLocation[0]);
          } else if(this.currentActiveLocation) {
            const selectedLocation = this.availableLocations.filter((x) => x.id == this.currentActiveLocation.locationId);

            this.model.selectedLocation = selectedLocation;

            await this.onLocationChanged(this.model.selectedLocation[0]);
          }

          if (this.model.selectedLocation.length && this.model.selectedLocation[0].showExtraField3) {
            await _getMiscData(this.model.selectedLocation[0].locationConfigurationId).then(response => this.specialField3Options = response.data)
          }

          this.model.selectedService = selectedValues.service || [];
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));

      await getAllCarriers()
        .then((resp) => {
          this.availableCarriers = resp.data;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
    async onCompanyChanged (item) {
      this.model.selectedLocation = [];
      this.model.selectedService = [];
      this.availableLocations = [];
      this.availableServices = [];

      this.model.selectedCompany = [item];
      this.model.companyId = item.id;

      this.availableSpecialists = [];
      this.model.selectedSpecialist = [];
      this.specialistAppointments = [];

      this.model.locationId = null;
      this.model.serviceId = null;
      this.model.specialistLocationId = null;

      await this.loadLocations(item.id);
    },
    async onLocationChanged (item) {
      this.model.selectedService = [];
      this.model.selectedSpecialist = [];
      this.availableServices = [];
      this.availableSpecialists = [];

      this.model.selectedLocation = [item];
      this.model.locationId = item.id;

      this.model.timeStart = null;
      this.model.timeEnd = null;

      this.model.serviceId = null;
      this.model.specialistLocationId = null;

      await this.loadServices(item.id);
    },
    async onServiceChanged(item) {
      this.model.selectedSpecialist = [];
      this.availableSpecialists = [];

      if(item.id) {
        this.model.selectedService = [item];
        this.model.serviceId = item.id;
      }
      else {
        this.model.selectedService = [];
        this.model.serviceId = null;
      }

      if(this.model.selectedService.length > 0) {
        getServiceType(this.model.serviceId).then(({ data }) => 
        {
          this.serviceConfiguration = data;
        });

        await this.loadSpecialists(this.model.locationId, this.model.serviceId);
      }

      this.model.specialistLocationId = null;
    },
    async onSpecialistChange(item) {
      this.model.selectedSpecialist = [item];
      this.model.specialistLocationId = item.id;

      this.setOfficeTimes();
    },
    async OnCarrierChange(item) {
      this.model.selectedCarrier = item ? [item] : [];
      this.model.carrierId = item ? item.value : null;
    },

    async loadLocations (companyId) {
      await _getLocationsForSpaceCreation(companyId)
        .then((response) => {
          this.availableLocations = response.data;
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    async loadServices (locationId) {
      await _getServiceTypesForSpaceCreation(locationId)
        .then((response) => {
          this.availableServices = response.data || [];
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    async loadSpecialists(locationId, serviceTypeId) {
      await _getAvailablesSpecialistForAppointments({
        locationId : locationId,
        serviceTypeId : serviceTypeId,
        isVirtual: false
      })
      .then((response) => {
        this.availableSpecialists = response.data;
      })
      .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    async searchAvailability() {
      this.showAvailabilityModal = true;
    },
    async searchProfile() {
      if (!this.model.email) {
        return;
      }

      await getProfile(this.model.email)
        .then((resp) => {
          if (resp.data && resp.data.hasProfile) {
            this.model.firstName = resp.data.firstName;
            this.model.paternalLastName =
              resp.data.lastPaternalName;
            this.model.maternalLastName =
              resp.data.lastMaternalName;
            this.model.phone = resp.data.phone;
            this.model.selectedCarrier = this.availableCarriers.filter(
              (p) => {
                return p.value === resp.data.carrierId;
              },
            );
            this.model.carrierId =
              this.model.selectedCarrier[0]?.value;
          } else {
            // Profile data reset
            this.model.firstName = '';
            this.model.paternalLastName = '';
            this.model.maternalLastName = '';
            this.model.phone = '';
            this.model.selectedCarrier = [];
            this.model.carrierId = null;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    setOfficeTimes() {
      if (this.model.selectedLocation && this.model.selectedLocation) {
        let officeOpenTime = this.model.selectedLocation[0].officeOpenTime;

        const openTime = moment().toDate();

        openTime.setHours(7);
        openTime.setMinutes(0);

        this.officeOpenTime = this.$moment(
          officeOpenTime || openTime,
          'HH:mm:ss',
        ).format();

        let officeCloseTime = this.model.selectedLocation[0].officeCloseTime;

        const closeTime = moment().toDate();

        closeTime.setHours(19);
        closeTime.setMinutes(0);

        this.officeCloseTime = this.$moment(
          officeCloseTime || closeTime,
          'HH:mm:ss',
        ).format();

        this.$nextTick(() => {
          this.model.timeEnd = this.$moment(this.model.timeStart, "YYYY-MM-DDTHH:mm:ss")
            .add({minutes: this.duration}).format();
        });
      }
    },
    onStartDateChanged(value) {
      this.model.startDate = value;
    },
    onHourFromChanged(newHourFrom) {
      this.model.timeStart = newHourFrom;
    },
    onHourToChanged(newHourTo) {
      let hourTo = this.$moment(newHourTo);
      if(!hourTo.isValid()) {
        hourTo = this.$moment(newHourTo, "HH:mm:ss");
      }
      this.model.timeEnd = hourTo.format();
    },

    onStartEndDateChanged({ startDate, endDate }) { 
      _getAvailableFreeSpaces({
        companyId: this.model.companyId,
        locationId: this.model.locationId,
        serviceId: this.model.serviceId,
        specialistId: this.model.specialistLocationId,
        dateStart: this.$moment(startDate).format(),
        dateEnd: this.$moment(endDate).format()
      })
      .then((response) => {
        this.specialistAppointments = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSpaceSelected(item) {
      this.model.startDate = item.date;
      this.model.timeStart = item.startHour;
      this.model.timeEnd = item.endHour;
      
      this.showAvailabilityModal = false;
    },
    onDurationChanged() {
      const mht = this.$moment(this.model.timeStart, "YYYY-MM-DDTHH:mm:ss")
        .set({ second: 0})
        .add({minutes: this.duration});

      this.model.timeEnd = mht.format();
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;

      await _createDirectAppointment(this.model)
        .then(() => {
          this.$router.push({ name: 'directAppointmentsSearch' });
          this.ShowSuccessSaveToast();
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>

<style lang="scss" scoped>
  .searchIcon {
    margin: 0 10px;
    font-size: 18px;
    color: $color-primary;
    cursor: pointer;
    margin-top: 10px;
  }
  .splitter {
    border: 1px solid lightgray;
    width: 100%;
    margin: 10px 0;
  }
  .c-timePicker, .duration {
    display: inline;
    float: left;
    margin-right: 15px;
  }
  .c-timePicker {
    max-width: 135px;
  }
  .duration {
    max-width: 155px;
  }
  .availability {
    margin-top: 28px;
  }
</style>