import { validateAndGetStamps as _validateAndGetStamps } from '@/services/AppointmentService';

const digitalCollectionMixin = {
  methods: {
    async validateAndGetStamps(appointmentId) {
      let appointmentStampsValidationInfo = { };

      await _validateAndGetStamps(appointmentId)
        .then((result) => {
          appointmentStampsValidationInfo = result.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => (this.loading = false));

      return appointmentStampsValidationInfo;
    },
  },
};

export default digitalCollectionMixin;
